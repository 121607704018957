import $camelCase from 'lodash/camelCase'
import $omit from 'lodash/omit'

export const jsonParse = (val, defaultVal = null) => {
  try {
    const json = JSON.parse(val)
    return json || defaultVal
  } catch {
    return defaultVal
  }
}

export const convertToCamelCase = (obj) => {
  if (Array.isArray(obj)) {
    return obj.map(v => convertToCamelCase(v))
  } else if (obj != null && obj.constructor === Object) {
    return Object.keys(obj).reduce((result, key) => ({
      ...result,
      [$camelCase(key)]: convertToCamelCase(obj[key]),
    }),
    {})
  }
  return obj
}

export const convertToOptionsFormat = (
  originOptions, labelKey, valueKey,
) => {
  let options = []

  if (Array.isArray(originOptions)) {
    options = originOptions.map((option) => {
      if (option[labelKey] !== void 0 && option[valueKey] !== void 0) {
        const omitOption = $omit(option, [labelKey, valueKey])
        return {
          ...omitOption,
          label: option[labelKey],
          value: option[valueKey],
        }
      } else {
        return option
      }
    })
  } else {
    options = Object.keys(originOptions || {}).map((type) => ({
      label: originOptions[type],
      value: type,
    }))
  }

  return options
}

export const moneyFormat = (num) => {
  if (Boolean(num) === false) return '0'

  const str = num.toString()
  const regex = str.indexOf('.') > -1 ? /(\d)(?=(\d{3})+\.)/g : /(\d)(?=(?:\d{3})+$)/g
  return str.replace(regex, '$1,')
}

export const numberFormat = (str) => {
  return parseInt(str.toString().replace(/[^0-9]/g, ''))
}

export const htmlTagFilter = (html) => {
  return html.replace(/<.*?>/g, '')
}

export const searchToObject = (search) => {
  var obj = {}
  var pairs = search.substring(1).split('&')
  for (var i in pairs) {
    if (pairs[i] === '') continue
    var pair = pairs[i].split('=')
    obj[decodeURIComponent(pair[0])] = decodeURIComponent(pair[1])
  }
  return obj
}

export const ecErrorCode = () => {
  const date = new Date()
  const code = [
    'EC',
    date.getFullYear().toString().slice(-2),
    `0${date.getMonth()}`.slice(-2),
    `0${date.getDate()}`.slice(-2),
    `0${date.getHours()}`.slice(-2),
    `0${date.getMinutes()}`.slice(-2),
  ].join('')
  return code
}

export const handleHeadParams = (ServerSideContext) => {
  const { req, query } = ServerSideContext
  const protocol = req.headers['x-forwarded-proto'] || 'http'
  const baseUrl = req ? `${protocol}://${req.headers.host}` : ''
  const currentUrl = `${baseUrl}${req.url}`
  const headers = req.headers
  const pathQuery = query

  return {
    currentUrl,
    headers,
    pathQuery,
    baseUrl,
  }
}

export const handleRatioStyle = (propsRatio) => {
  return isNaN(Number(propsRatio)) === false && propsRatio > 0
    ? { paddingTop: `${100 / propsRatio}%` }
    : null
}

export const processedHtmlString = (html) => {
  // 取html結構內的文字 
  // 商品頁遇到=> SEO需用SSR拿到的API資料,原使用DOMParser取Html裡內容,但SSR無法用WebAPI (DOMParser)
  // 故使用正則取得html結構內的文字,目前串接KKday沒問題
  // ----雄獅串結時要注意!!----
  const stripHtml = (html) => {
    return html.replace(/(\r\n|\n|\r)/gm, '').replace(/<[^>]*>?/gm, '')
  }

  const modifiedHtmlString = html
    .replace(/\s+/g, '') // 去除空白
    .replace(/&nbsp;/g, '')
    .replace(/<\/li>/g, ';</li>') // 將</li>作為結尾,加上分號

  const escapeHtml = stripHtml(modifiedHtmlString)
    .replace(/&/g, '&amp;')
    .replace(/</g, '&lt;')
    .replace(/>/g, '&gt;')
    .replace(/"/g, '&quot;')
    .replace(/'/g, '&apos;')
    
  return escapeHtml
}

// 全形轉半形
export const toHalfWidth = (str) => {
  return str.replace(/[\uff01-\uff5e]/g, function (ch) {
    return String.fromCharCode(ch.charCodeAt(0) - 0xfee0)
  }).replace(/\u3000/g, ' ')
}

// 取得指定 JSON 資料
export const getJsonData = async (path) => {
  // 取得指定 JSON 資料
  const res = await fetch(path)
  const data = await res.json()
  return data
}