/*
 * UI component - CardSection 卡片內框
 *  props
 *   ├ className (string): 添加 / 覆蓋樣式
 *   ├ id (string): 用於增加識別
 *   ├ horizontal (boolean): 是否讓內容水平並排，預設 false
 *   └ children (node): 卡片內框內容
 *
 *  補充
 *   ├ horizontal 圖片圓角設定與 UI 設計有衝突，因此目前 'card__section--horiz' css 都註解掉
 *   └ 與 Card 搭配，也可分開使用
 */

import { memo } from 'react'
import PropTypes from 'prop-types'
import classReader from 'utils/classReader'

const CardSection = (props) => {
  const {
    className,
    id,
    horizontal,
    children,
  } = props

  return (
    <div
      className={
        classReader(
          'card__section',
          horizontal ? 'card__section--horiz row no-wrap' : 'card__section--vert',
          className,
        )}
      id={id}
    >
      {children}
    </div>
  )
}

CardSection.propTypes = {
  className: PropTypes.string,
  id: PropTypes.string,
  horizontal: PropTypes.bool,
  children: PropTypes.node,
}

CardSection.defaultProps = { horizontal: false }

export default memo(CardSection)
