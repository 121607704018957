import { memo } from 'react'
import PropTypes from 'prop-types'
import classReader from 'utils/classReader'
import Image from 'next/image'

import { handleRatioStyle } from 'utils/util'

import NoPicture from 'public/no-picture.svg'

const Img = (props) => {
  const {
    className,
    styleName,
    ratioClassName,
    id,
    src,
    ratio,
    width,
    height,
    alt,
    priority,
    defaultBg,
    topContent,
    bottomContent,
    leftContent,
    rightContent,
    topLeftContent,
    topRightContent,
    bottomLeftContent,
    bottomRightContent,
    topBackdrop,
    bottomBackdrop,
    leftBackdrop,
    rightBackdrop,
    topLeftBackdrop,
    topRightBackdrop,
    bottomLeftBackdrop,
    bottomRightBackdrop,
    children,
  } = props



  return (
    <div className={classReader('img', className)} id={id}>
      <div className={classReader({ [`CommonStyled ${styleName}`]: 'img__container' }, { 'img__container--bg': defaultBg })}> {/* can overwritten this className style */}
        {Number(ratio) > -1 && (
          <div
            className={classReader('img__aspect-ratio', { [ratioClassName]: Boolean(ratioClassName) })}
            style={Boolean(ratio) ? handleRatioStyle(ratio) : { display: 'block' }}
          />
        )}
        <Image
          className={classReader(
            'img__image',
            { 'img__image--width-full': Boolean(width) === false },
            { 'img__image--height-full': Boolean(height) === false },
            { 'absolute-full': Boolean(ratio) },
            { 'invisible': Boolean(src) === false },
          )}
          src={src || NoPicture}
          alt={alt}
          width={width || 100}
          height={height || 100}
          priority={priority}
          // loading={priority ? 'eager' : 'lazy'}
        />
      </div>

      <div className={classReader('img__content absolute-full')}>
        {/* top bottom right left */}
        {Boolean(topContent) && (
          <div className={classReader('absolute-top', { 'img--backdrop': topBackdrop })}>
            {topContent}
          </div>
        )}

        {Boolean(bottomContent) && (
          <div className={classReader('absolute-bottom', { 'img--backdrop': bottomBackdrop })}>
            {bottomContent}
          </div>
        )}

        {Boolean(leftContent) && (
          <div className={classReader('absolute-left', { 'img--backdrop': leftBackdrop })}>
            {leftContent}
          </div>
        )}

        {Boolean(rightContent) && (
          <div className={classReader('absolute-right', { 'img--backdrop': rightBackdrop })}>
            {rightContent}
          </div>
        )}

        {/* top-left top-right */}
        {Boolean(topLeftContent) && (
          <div className={classReader('absolute-top-left', { 'img--backdrop': topLeftBackdrop })}>
            {topLeftContent}
          </div>
        )}

        {Boolean(topRightContent) && (
          <div className={classReader('absolute-top-right', { 'img--backdrop': topRightBackdrop })}>
            {topRightContent}
          </div>
        )}

        {/* bottom-left bottom-right */}
        {Boolean(bottomLeftContent) && (
          <div className={classReader('absolute-bottom-left', { 'img--backdrop': bottomLeftBackdrop })}>
            {bottomLeftContent}
          </div>
        )}

        {Boolean(bottomRightContent) && (
          <div className={classReader('absolute-bottom-right', { 'img--backdrop': bottomRightBackdrop })}>
            {bottomRightContent}
          </div>
        )}

        {children}
      </div>
    </div>
  )
}

Img.propTypes = {
  className: PropTypes.string,
  styleName: PropTypes.string,
  ratioClassName: PropTypes.string,
  id: PropTypes.string,
  src: PropTypes.oneOfType([PropTypes.string, PropTypes.object]),
  ratio: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
  width: PropTypes.number,
  height: PropTypes.number,
  alt: PropTypes.string,
  priority: PropTypes.bool,
  defaultBg: PropTypes.bool,
  topContent: PropTypes.node,
  bottomContent: PropTypes.node,
  leftContent: PropTypes.node,
  rightContent: PropTypes.node,
  topLeftContent: PropTypes.node,
  topRightContent: PropTypes.node,
  bottomLeftContent: PropTypes.node,
  bottomRightContent: PropTypes.node,
  topBackdrop: PropTypes.bool,
  bottomBackdrop: PropTypes.bool,
  topLeftBackdrop: PropTypes.bool,
  topRightBackdrop: PropTypes.bool,
  bottomLeftBackdrop: PropTypes.bool,
  bottomRightBackdrop: PropTypes.bool,
  children: PropTypes.node,
}

Img.defaultProps = {
  styleName: '',
  priority: false,
  defaultBg: false,
  topBackdrop: false,
  bottomBackdrop: false,
  topLeftBackdrop: false,
  topRightBackdrop: false,
  bottomLeftBackdrop: false,
  bottomRightBackdrop: false,
}

export default memo(Img)