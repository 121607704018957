import { createAxios } from 'apis/axios'

const cart = {
  /**
   * @function getCartInfo 購物車 - 取得購物車內容
   * 
   * // response 200 / 400 / 401
   * @returns {
   *   {
   *      success: boolean,
   *      messages: [{
   *        errorCode: string,
   *        message: [{
   *          language: string,
   *          message: string
   *        }]
   *      }],
   *      data: array
   *   }
   * }
   */
  getCartInfo: async (params = {}) => {
    const result = await createAxios().post('Cart/Get', params)
    return result.data
  },
  /**
   * @function addCartProduct 新增購物車內容
   * @param { object } params
   * @param { number } params.productItemNumber - 方案編號
   * @param { string } params.targetDate - 購買起始日
   * @param { string } params.endDate - 購買結束日 (目前第一階段起訖日皆為同一天)
   * @param { {
   *     targetDate: string,
   *     quantity: number
   *   }[]
   * } params.selectedItem - 選擇規格 { 目標日期, 訂購數量 }
   * @param { string } params.diversionSource - 導購來源-目前僅EC DiversionEC : 由EC加入購物車
   *
   * // response 200 / 400
   * @returns {
   *   {
   *      success: boolean,
   *      messages: string
   *   }
   * }
   */
  addCartProduct: async (params) => {
    const result = await createAxios().post('Cart', params)
    return result.data
  },
  /**
   * @function getDiversionCartInfo 購物車 - 透過 DiversionSource 取得特定購物車內容
   * @param { string } diversionSource
   * 
   * // response 200 / 400 / 401
   * @returns {
   *   {
   *      success: boolean,
   *      messages: [{
   *        errorCode: string,
   *        message: [{
   *          language: string,
   *          message: string
   *        }]
   *      }],
   *      data: 待確定
   *   }
   * }
   */
  getDiversionCartInfo: async (diversionSource) => {
    const result = await createAxios().get(`Cart/diversion/${diversionSource}`)
    return result.data
  },
  /**
   * @async
   * @function deleteCartProduct 購物車 - 刪除購物車內容
   * @param { number } cartId
   * 
   * // response 200 / 400
   * @returns {
   *   {
   *      success: boolean,
   *      messages: [{
   *        errorCode: string,
   *        message: [{
   *          language: string,
   *          message: string
   *        }]
   *      }]
   *   }
   * }
   */
  deleteCartProduct: async (params) => {
    const result = await createAxios().post('Cart/DeleteList', params)
    return result.data
  },
  /**
   * @async
   * @function getHotaiGoCartCount HOTAI購 - 購物車商品總數
   * @param { } 
   * 
   * // response 200 / 400
   * @returns {
  *   {
  *      success: boolean,
  *      messages: [{
  *        errorCode: string,
  *        message: [{
  *          language: string,
  *          message: string
  *        }]
  *      }],
  *      data: {
  *        count: number
  *      }
  *   }
  * }
  */
  getCartCount: async () => {
    const result = await createAxios().get('Cart/Count')
    return result.data
  },
  getHotaiGoCartCount: async () => {
    const result = await createAxios().get('Cart/hotaigo/count')
    return result.data
  },
}

export default cart
