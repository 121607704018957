import { jsonParse } from 'utils/util'

export const localStorage = {
  set: (key, data) => {
    window.localStorage.setItem(key, data)
  },
  setString: (key, data) => {
    window.localStorage.setItem(key, JSON.stringify(data))
  },
  get: (key, defaultVal = null) => {
    return window.localStorage.getItem(key) || defaultVal
  },
  getParse: (key, defaultVal = null) => {
    return jsonParse(window.localStorage.getItem(key), defaultVal)
  },
  remove: (key) => {
    window.localStorage.removeItem(key)
  },
  clear: () => {
    window.localStorage.clear()
  },
  clearExpectAntiFraud: () => {
    const antiFraud = window.localStorage.getItem('anti-fraud')
    window.localStorage.clear()
    if (antiFraud) window.localStorage.setItem('anti-fraud', antiFraud)
  },
  createSetEvent: () => {
    const originalSetItem = window.localStorage.setItem

    // 重製 setItem
    window.localStorage.setItem = function (key, newVal) {
      // 創建 setItemEvent 事件
      const event = new Event('setItemEvent')
      event.key = key
      event.newVal = newVal
      // 提交 setItemEvent 事件
      window.dispatchEvent(event)
      // 執行原 setItem function
      originalSetItem.apply(this, arguments)
    }
  },
}

export const sessionStorage = {
  set: (key, data) => {
    window.sessionStorage.setItem(key, data)
  },
  setString: (key, data) => {
    window.sessionStorage.setItem(key, JSON.stringify(data))
  },
  get: (key, defaultVal = null) => {
    return window.sessionStorage.getItem(key) || defaultVal
  },
  getParse: (key, defaultVal = null) => {
    return jsonParse(window.sessionStorage.getItem(key), defaultVal)
  },
  remove: (key) => {
    window.sessionStorage.removeItem(key)
  },
  clear: () => {
    window.sessionStorage.clear()
  },
}
