import { createAsyncThunk, createSlice } from '@reduxjs/toolkit'
import { LOCAL_STORAGE } from 'config/config'
import { localStorage } from 'utils/storage'
import member from 'apis/auth/member'
import { setErrorState } from 'slices/errorSlice'

const memberInitial = {
  name: '',
  appellation: 0,
  email: '',
  phone: '',
}

export const getMemberInfo = createAsyncThunk('member/getMemberInfo',
  async (params, thunkAPI) => {
    const hasRefresh = localStorage.get(LOCAL_STORAGE.PROFILE_REFRESH)

    try {
      const memberInfoApi = hasRefresh === 'true' ? member.memberInfoRefresh : member.getMemberInfo
      const result = await memberInfoApi()
      const { data } = result
      localStorage.remove(LOCAL_STORAGE.PROFILE_REFRESH)
      thunkAPI.dispatch({
        type: 'member/setMemberState',
        payload: data, 
      })
      // return data
    } catch (error) {
      thunkAPI.dispatch(setErrorState(error))
      // throw thunkAPI.rejectWithValue(error)
    }
  })


export const memberSlice = createSlice({
  name: 'member',
  initialState: memberInitial,
  reducers: {
    setMemberState(state, action) {
      return {
        ...state,
        ...action.payload, 
      }
    },
  },
  extraReducers: (builder) => {
    builder
      .addMatcher((action) => {
        return action.type.endsWith('/fulfilled') &&
            (
              action.type.startsWith('localAuth/ecLocalSignIn') ||
              action.type.startsWith('auth/ecSignIn')
            )
      },
      (state, action) => {
        state.name = action.payload.name
      })
      .addMatcher((action) => (
        action.type === 'auth/resetAuthSetting' ||
          action.type === 'auth/ecSignOut/fulfilled' ||
          action.type === 'auth/ecSignOut/rejected' ||
          action.type === 'auth/ecRefreshToken/rejected'
      ),
      () => memberInitial)
  },
})

const { actions, reducer } = memberSlice

export const { setMemberState } = actions

export const selectName = (state) => state.member.name
export const selectMember = (state) => state.member
export default reducer