import Link from 'next/link'
import { useRouter } from 'next/router'
import { useEffect, useState } from 'react'
import classReader from 'utils/classReader'

export const goToTop = () => {
  window.scrollTo({
    top: 0,
    behavior: 'smooth',
  })
}

const ScrollUp = (props) => {
  const router = useRouter()
  const { handleAntiFraudClose } = props
  const [show, setShow] = useState(false)
  const [withBottom, setWithBottom] = useState(false)


  useEffect(() => {
    const handleScroll = () => {
      if (window.scrollY > 100) {
        setShow(true)
      } else {
        setShow(false)
      }
    }

    window.addEventListener('scroll', handleScroll)

    return () => {
      window.removeEventListener('scroll', handleScroll)
    }
  }, [])

  useEffect(() => {
    const pageWithBottom = ['/product', '/cart']
    const shouldAddBottom = pageWithBottom.some(page => router.pathname.includes(page))
    setWithBottom(shouldAddBottom)
  }, [router])

  return (
    <div className={classReader('toolbar flex-column', { 'toolbar--withBottom': withBottom })}>

      <div className={classReader('border d-flex flex-column justify-content-center align-items-center rounded-100 icon-anti_fraud cursor-pointer')} onClick={handleAntiFraudClose}>
        <p className={classReader('m-0 text-white')}>防止</p>
        <p className={classReader('m-0 text-white')}>詐騙</p>
      </div>
        
      <Link href={'/custom-service'}>
        <div className={classReader('border d-flex flex-column justify-content-center align-items-center bg-gray-1 rounded-100 icon-custom_service')}>
          <i className={classReader('icon icon-support-agent icon-primary')} />
        </div>
      </Link>

      <i className={classReader('icon icon-goto shrink-0 toolbar__icon__to-top', { 'toolbar__icon--hide': show === false })} onClick={goToTop}/>
    </div>
  )
}

export default ScrollUp