import { memo } from 'react'
import PropTypes from 'prop-types'
import classReader from 'utils/classReader'
import Link from 'next/link'
import Image from 'next/image'
import Modal from 'common/Modal'
import Checkbox from 'common/form/Checkbox'

const AntiFraud = (props) => {
  const {
    NOTICE_DATA,
    VERIFIED_DATA,
    FOOTER_COMPANY_INFO,
    prompt,
    isChecked,
    onClose,
    onChange,
  } = props

  return (
    <Modal
      className={classReader({ IndexStyled: ['anti-fraud'] }, 'd-flex flex-column overflow-hidden')}
      title="防詐騙宣導"
      prompt={prompt}
      onClose={onClose}
      close
    >
      <div className={classReader('scrollbar-y px-3 mt-2', { IndexStyled: ['anti-fraud__body'] } )}>
        <div className={classReader('text-primary text-sm text-sm-md')}>
          <div className={classReader('mb-3')}>
            <div className={classReader('d-flex align-items-center')}>
              <i className={classReader('icon icon-campaign icon-primary icon-sm icon-sm-md mr-2')} /> 請注意
            </div>
            <div className={classReader('row mt-2', { IndexStyled: ['anti-fraud__body__list'] })}>
              {NOTICE_DATA.map((item, index) => <div key={index} className={classReader('col-6')}>
                <div className={classReader('d-flex align-items-center text-nowrap mt-1 mt-sm-2')}>
                  <i className={classReader('icon icon-close icon-secondary icon-sm icon-sm-md')} /> {item}
                </div>
              </div>)}
            </div>
          </div>
        
          <div className={classReader('mb-3')}>
            <div className={classReader('d-flex align-items-center')}>
              <i className={classReader('icon icon-verified-fill icon-primary icon-sm icon-sm-md mr-2')} /> 請認證
            </div>
            <div>
              {VERIFIED_DATA.map((item, index) => <div key={index} className={classReader('mt-2')}>
                {item.text}：
                <Link
                  className={classReader('text-nowrap text-primary d-block d-sm-unset')} 
                  prefetch={false}
                  href={item.url} passHref>
                  {item.url}
                </Link>
              </div>)}
            </div>
          </div>

          <div>
            <div>如遇可疑訊息，務必小心查證</div>
            <div>
            若有任何疑問，請致電客服
              {FOOTER_COMPANY_INFO.customer.tel.map((value, index) => <span key={index}>
                {index > 0 && ' / '}
                <Link
                  key={index}
                  className={classReader('text-nowrap text-primary')}
                  prefetch={false} 
                  href={`tel:${value}`} 
                  passHref
                >
                  {value}
                </Link>
              </span>)}
            或撥打165反詐騙專線
            </div>
            <div>
            服務時間：{FOOTER_COMPANY_INFO.customer.serviceHours}
            </div>
          </div>
        </div>
      </div>
     
      {/* <div className={classReader({ IndexStyled: ['anti-fraud__footer'] }, 'bg-primary text-white')}>
        <Checkbox
          className={classReader('cursor-pointer')}
          label="今日不再顯示此公告"
          name="checkTest"
          color="primary"
          bgClassName="bg-white"
          svgClassName="text-primary"
          checked={isChecked}
          onChange={(value, checked) => onChange(checked)}
          onClose={() => onClose()}
          keepColor
        />
      </div> */}
    </Modal>
  )
}

AntiFraud.propTypes = {
  prompt: PropTypes.bool,
  isChecked: PropTypes.bool,
  onClose: PropTypes.func, 
  onChange: PropTypes.func, 
}

AntiFraud.defaultProps = { prompt: false }

export default memo(AntiFraud)