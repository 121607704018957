import {
  useRef,
  useState,
  useEffect,
  useCallback,
  memo,
} from 'react'
import PropTypes from 'prop-types'
import classReader from 'utils/classReader'
import { Swiper, SwiperSlide } from 'swiper/react'
import {
  Autoplay,
  Pagination,
  Navigation,
  EffectCoverflow,
  EffectCreative,
} from 'swiper'


import 'swiper/css'
import 'swiper/css/pagination'
import 'swiper/css/navigation'
import 'swiper/css/effect-coverflow'


const Slider = (props) => {
  const {
    className,
    styleName,
    id,
    slides,
    slidesPerView,
    slidesPerGroup,
    spaceBetween,
    initialSlide,
    breakpoints,
    effect,
    coverflowEffect,
    creativeEffect,
    autoplay,
    pagination,
    navigation,
    loop,
    centeredSlides,
    isSlideReset,
    extendedWidth,
    autoWidth,
    buttonOutside,
    customLeftNavigation,
    customRightNavigation,
    onBreakpoint,
  } = props

  const [prevSliderHide, setPrevSliderHide] = useState(false)
  const [nextSliderHide, setNextSliderHide] = useState(false)
  const [mouseEnterId, setMouseEnterId] = useState('slider0')
  const sliderRef = useRef(null)

  const widthAttr = (slideId) => {
    let attr = {}

    if (extendedWidth) {
      attr = {
        className: classReader('swiper-slide--extended-width'),
        'data-active': mouseEnterId === slideId,
        onMouseEnter: () => {
          if (mouseEnterId !== slideId) setMouseEnterId(slideId)
        },
      }
    } else if (autoWidth) {
      attr = { className: classReader('swiper-slide--auto-width') }
    }

    return attr
  }

  const handleButtonVisible = useCallback(() => {
    setPrevSliderHide(sliderRef?.current?.swiper?.isBeginning ?? true)
    setNextSliderHide(sliderRef?.current?.swiper?.isEnd ?? true)
  }, [sliderRef?.current])

  const handlePrev = useCallback(() => {
    if (sliderRef.current === null) return
    sliderRef.current.swiper.slidePrev()
    handleButtonVisible()
  }, [handleButtonVisible])

  const handleNext = useCallback(() => {
    if (sliderRef.current === null) return
    sliderRef.current.swiper.slideNext()
    handleButtonVisible()
  }, [handleButtonVisible])

  useEffect(() => {
    if (slides.length && isSlideReset) sliderRef?.current?.swiper.slideTo(0, 1)
  }, [slides])

  useEffect(() => {
    handleButtonVisible()
  }, [handleButtonVisible])

  return (
    <div className={classReader('slider', className)}>
      {customLeftNavigation(handlePrev) !== void 0 && (
        <div className={
          classReader(
            { [`CommonStyled ${styleName}`]: 'swiper__prepend' }, // can overwritten this className style
            { 'swiper__prepend--outside': buttonOutside },
            { 'swiper--disabled': prevSliderHide && loop === false },
          )}
        >
          {customLeftNavigation(handlePrev)}
        </div>
      )}

      <Swiper
        ref={sliderRef}
        id={id}
        initialSlide={initialSlide}
        slidesPerView={slidesPerView}
        slidesPerGroup={slidesPerGroup}
        spaceBetween={spaceBetween}
        breakpoints={breakpoints}
        modules={[
          Autoplay,
          Pagination,
          Navigation,
          EffectCoverflow,
          EffectCreative,
        ]}
        pagination={pagination && { clickable: true }}
        navigation={navigation}
        speed={450}
        autoplay={autoplay}
        loop={loop}
        centeredSlides={centeredSlides}
        effect={effect}
        coverflowEffect={coverflowEffect}
        creativeEffect={creativeEffect}
        onSlideChange={handleButtonVisible}
        onBreakpoint={onBreakpoint}
        roundLengths
        watchOverflow
        lazy="true"
      >
        {slides?.map(slide => (
          <SwiperSlide key={slide.id} {...widthAttr(slide.id)}>
            {slide.content}
          </SwiperSlide>
        ))}
      </Swiper>

      {customRightNavigation(handleNext) !== void 0 && (
        <div className={
          classReader(
            { [`CommonStyled ${styleName}`]: 'swiper__append' }, // can overwritten this className style
            { 'swiper__append--outside': buttonOutside },
            { 'swiper--disabled': nextSliderHide && loop === false },
          )}
        >
          {customRightNavigation(handleNext)}
        </div>
      )}
    </div>
  )
}

Slider.propTypes = {
  className: PropTypes.string,
  styleName: PropTypes.string,
  id: PropTypes.string,
  slides: PropTypes.array,
  slidesPerView: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
  slidesPerGroup: PropTypes.number,
  spaceBetween: PropTypes.number,
  initialSlide: PropTypes.number,
  breakpoints: PropTypes.object,
  effect: PropTypes.string,
  coverflowEffect: PropTypes.object,
  creativeEffect: PropTypes.object,
  autoplay: PropTypes.oneOfType([PropTypes.object, PropTypes.bool]),
  pagination: PropTypes.bool,
  navigation: PropTypes.bool,
  loop: PropTypes.bool,
  centeredSlides: PropTypes.bool,
  isSlideReset: PropTypes.bool,
  extendedWidth: PropTypes.bool,
  autoWidth: PropTypes.bool,
  buttonOutside: PropTypes.bool,
  customLeftNavigation: PropTypes.func,
  customRightNavigation: PropTypes.func,
  onBreakpoint: PropTypes.func,
}

Slider.defaultProps = {
  slides: [],
  spaceBetween: 0,
  slidesPerView: 1,
  slidesPerGroup: 1,
  initialSlide: 0,
  breakpoints: {},
  coverflowEffect: {},
  covertiveEffect: {},
  autoplay: false,
  pagination: false,
  navigation: false,
  loop: false,
  centeredSlides: false,
  isSlideReset: false,
  extendedWidth: false,
  autoWidth: false,
  buttonOutside: false,
  customLeftNavigation: (prevFunc) => { },
  customRightNavigation: (nextFunc) => { },
  onBreakpoint: (breakpointEvent) => { },
}

export default memo(Slider)