import { createAxios } from 'apis/axios'
import { APP_ID } from 'config/config'

const auth = {
  /**
   * @function ecTestSignIn 測試使用 - 和泰集團會員登入 (本地快速登入用)
   * 
   * // response 200 / 400 / 401 / 403
   * @returns {
   *   {
   *      success: boolean,
   *      messages: [{
   *        errorCode: string,
   *        message: [{
   *          language: string,
   *          message: string
   *        }]
   *      }],
   *      data: {
   *        access_Token: string,
   *        token_Type: string,
   *        refresh_Token: string,
   *        memberState: number,
   *        isCheckEmail: false
   *      } || null
   *   }
   * }
   */
  ecTestSignIn: async () => {
    const params = {}
    const result = await createAxios().post('Auth/hotaimember/signin', params)
    return result.data
  },
  /**
   * @function ecSignIn EC 會員 - 註冊或登入 EC 會員
   * @param { Object } params
   * @param { string } params.accessToken
   * @param { string } params.refreshTokens
   * 
   * // response 200 / 400 / 401 / 403
   * @returns {
   *   {
   *      success: boolean,
   *      messages: [{
   *        errorCode: string,
   *        message: [{
   *          language: string,
   *          message: string
   *        }]
   *      }],
   *      data: {
   *        name: string,
   *        accessToken: string,
   *        hotaiPoint: number
   *      } || null
   *   }
   * }
   */
  ecSignIn: async (params) => {
    const result = await createAxios().post('Auth/oauth/signin', params)
    return result.data
  },
  /**
   * @function ecSignOut EC 會員 - 登出會員
   * 
   * // response 200 / 400
   * @returns {
   *   {
   *      success: boolean,
   *      messages: [{
   *        errorCode: string,
   *        message: [{
   *          language: string,
   *          message: string
   *        }]
   *      }]
   *   }
   * }
   */
  ecSignOut: async () => {
    const result = await createAxios().post('Auth/signout')
    return result.data
  },
  /**
   * @function ecVerifyToken EC 會員 - 驗證 Token
   * 
   * // response 200 / 400 / 401 / 403
   * @returns {
   *   {
   *      success: boolean,
   *      messages: [{
   *        errorCode: string,
   *        message: [{
   *          language: string,
   *          message: string
   *        }]
   *      }]
   *   }
   * }
   */
  ecVerifyToken: async () => {
    const result = await createAxios().post('Auth/VerificationToken')
    return result.data
  },
  /**
   * @function ecRefreshToken EC 會員 - 更新 Token
   * @param { Object } params
   * @param { string } params.accessToken
   * 
   * // response 200 / 400 / 401 / 403
   * @returns {
   *   {
   *      success: boolean,
   *      messages: [{
   *        errorCode: string,
   *        message: [{
   *          language: string,
   *          message: string
   *        }]
   *      }],
   *      data: {
   *        accessToken: string
   *      } || null
   *   }
   * }
   */
  ecRefreshToken: async (params) => {
    const result = await createAxios().post('Auth/RefreshToken', params)
    return result.data
  },
  /**
   * @function ecGetMemberInfoEditUrl EC 會員 - 取得和泰集團會員_修改會員資料 URL
   * 
   * // response 200 / 400 / 401 / 403
   * @returns {
   *   {
   *      success: boolean,
   *      messages: [{
   *        errorCode: string,
   *        message: [{
   *          language: string,
   *          message: string
   *        }]
   *      }],
   *      data: {
   *        modifyRedirectUri: string
   *      } || null
   *   }
   * }
   */
  ecGetMemberInfoEditUrl: async () => {
    const result = await createAxios().post('Auth/ModifyRedirectUri')
    return result.data
  },
  /**
   * @function ecExchangeIn 會員轉導 - 外部登入 EC
   * @param { Object } params
   * @param { string } params.token
   * @param { string } [params.fromPlatform] - 跳轉平台 (非必填)
   * 
   * // response 200 / 400 / 401 / 403
   * @returns {
   *   {
   *      success: boolean,
   *      messages: [{
   *        errorCode: string,
   *        message: [{
   *          language: string,
   *          message: string
   *        }]
   *      }],
   *      data: {
   *        name: string,
   *        accessToken: string,
   *        hotaiPoint: number
   *      } || null
   *   }
   * }
   */
  ecExchangeIn: async (params) => {
    const result = await createAxios().post('Auth/HotaiMember/ExchangeIn', params)
    return result.data
  },
  /**
   * @function ecExchangeOut 會員轉導 - EC 登入外部服務
   * @param { Object } params
   * @param { string } params.fromPlatform - 跳轉平台
   * 
   * // response 200 / 400
   * @returns {
   *   {
   *      success: boolean,
   *      messages: [{
   *        errorCode: string,
   *        message: [{
   *          language: string,
   *          message: string
   *        }]
   *      }],
   *      data: {
   *        token: string
   *      } || null
   *   }
   * }
   */
  ecExchangeOut: async (params) => {
    const result = await createAxios().post('Auth/HotaiMember/ExchangeOut', params)
    return result.data
  },
}

export default auth
