import { createAxios } from 'apis/axios'

const product = {
  /**
   * @function searchBarProduct 商品 - 搜尋欄商品查詢
   * @param { string } keyWord
   * 
   * // response 200 / 400
   * @returns {
   *   {
   *      success: boolean,
   *      messages: [{
   *        errorCode: string,
   *        message: [{
   *          language: string,
   *          message: string
   *        }]
   *      }],
   *      data: {
   *        products: [
   *          {
   *            productNumber: string,     // 商品編號
   *            city: string,              // 城市名稱
   *            country: string,           // 國家名稱
   *            title: string,             // 文案主標題
   *          mediaPath: string[]        // 多媒體路徑
   *          }
   *        ]
   *      }
   *   }
   * }
   */
  searchBarProduct: async (keyWord) => {
    const result = await createAxios().post('Product/SearchBar', keyWord)
    return result.data
  },
  /**
   * @function searchProduct 商品 - 商品搜尋
   * @param { object } params
   * @param { string[] } [params.cityCode] - 縣市/地區 (非必填)
   * @param { string[] } [params.productGroup] - 商品類型 (非必填)
   * @param { string[] } [params.productCategories] - 商品活動類型 (非必填)
   * @param { string } [params.duration] - 行程長度 (非必填)
   * @param { string } [params.description] - 商品名稱 (非必填)
   * @param { string } [params.targetDateStart] - 出發日期起 (非必填)
   * @param { string } [params.targetDateEnd] - 出發日期訖 (非必填)
   * @param { number } params.productPriceMin - 商品價錢下限 (目前為必填，後端會再調整)
   * @param { number } [params.productPriceMax] - 商品價錢上限 (非必填)
   * @param { number[] } [params.productIds] - 指定商品編號 list (非必填)
   * @param { number } [params.page] - 頁碼 (非必填)
   * @param { number } [params.take] - 幾筆 (非必填)
   * @param { string } [params.sortBy] - 排序依據 (非必填) ("MinPrice" / "ProductId")
   * @param { string } [params.sortDirection] - 排序正逆向 (非必填) ("ASC" / "DESC")
   * @param { boolean } [params.resetCatch] - 待向後端確認用意 (非必填)
   * 
   * // response 200 / 400
   * @returns {
   *   {
   *      success: boolean,
   *      messages: [{
   *        errorCode: string,
   *        message: [{
   *          language: string,
   *          message: string
   *        }]
   *      }],
   *      data: [
   *        {
   *          number: string,            // 商品編號
   *          city: string,              // 城市名稱
   *          country: string,           // 國家名稱
   *          saleStartDate: string,     // 最早銷售日期
   *          durationInHour: string,    // 所需時長
   *          title: string,             // 文案主標題
   *          description: string,       // 文案內容
   *          price: number,             // 價格
   *          minPrice: number,          // 最低價
   *          isCancelFree: boolean,     // 是否可免費取消
   *          isInstantBooking: boolean, // 及時預定 (即買即用)
   *          mediaPath: string[]        // 多媒體路徑
   *        }
   *      ]
   *   }
   * }
   */
  searchProduct: async (params) => {
    const result = await createAxios().post('Product/Search', params)
    return result.data
  },
  /**
   * @function getProductInfo 商品 - 單一產品資訊
   * @param { string } productDesc - 商品 id
   *
   * // response 200 (欄位過多，詳細請自行參閱 swagger) / 400
   * @returns {
   *   {
   *      success: boolean,
   *      messages: [{
   *        errorCode: string,
   *        message: [{
   *          language: string,
   *          message: string
   *        }]
   *      }],
   *      data: {
   *        city: string,
   *        country: string,
   *        days: date-time,
   *        hotaiPointType: string,
   *        hotaiPointValue: number,
   *        id: number,
   *        isAllSoldOut: boolean,
   *        isInstantBooking: boolean,
   *        isSaleByDate: boolean,
   *        minPrice: number,
   *        number: string,
   *        price: number,
   *        productDesc: object,
   *        productIntro: string,
   *        productIntroMenu: string,
   *        productItemSpec: object[],
   *        productMediaMap: object,
   *        productName: string
   *      }
   *   }
   * }
   */
  getProductInfo: async (productNumber) => {
    const result = await createAxios().get(`Product/SearchDtl?productNumber=${productNumber}`)
    return result.data
  },
  getProductInfoItem: async (productNumber) => {
    const result = await createAxios().get(`Product/SearchProductItemOption?productNumber=${productNumber}`)
    return result.data
  },

  /**
   * @function getPromoteProduct 商品 - 推薦商品
   *
   * // response 200 / 400
   * @returns {
   *   {
   *      success: boolean,
   *      messages: [{
   *        errorCode: string,
   *        message: [{
   *          language: string,
   *          message: string
   *        }]
   *      }],
   *      data: [
   *        {
   *          number: string,            // 訂單編號
   *          city: string,              // 城市名稱
   *          country: string,           // 國家名稱
   *          saleStartDate: string,     // 最早銷售日期
   *          setOffDate: string,        // 出發日期  (未使用到，可和後端討論移除)
   *          durationInHour: string,    // 所需時長
   *          title: string,             // 文案主標題
   *          description: string,       // 文案內容
   *          price: number,             // 價格
   *          minPrice: number,          // 最低價
   *          isCancelFree: boolean,     // 是否可免費取消
   *          isInstantBooking: boolean, // 及時預定 (即買即用)
   *          mediaPath: string[]        // 多媒體路徑
   *        }
   *      ]
   *   }
   * }
   */
  getPromoteProduct: async () => {
    const result = await createAxios().get('Promote')
    return result.data
  },
  /**
    * @function getHomePageProduct 商品 - 首頁商品資訊
    *
    * // response 200 / 400
    * @returns {
    *   {
    *      success: boolean,
    *      messages: [{
    *        errorCode: string,
    *        message: [{
    *          language: string,
    *          message: string
    *        }]
    *      }],
    *      data: {
    *        treeNodeList: [
    *          {
    *            positionName: string,      // 分類 (ex. BIGBANNER)
    *            titleName: string,         // 分類名稱 (ex. 首頁輪播圖)
    *            childrenNodes: [           // 子階層
    *              {
    *                titleName: string,     // 子階層分類名稱
    *                productNumber: string, // 商品 id
    *                imagePath: string,     // 商品圖片 url
    *                url: string,           // 商品網址 (可忽略該欄位，網址路徑跟前端設定的不同)
    *                childrenNodes: [...]
    *              }
    *            ]
    *          }
    *        ]
    *      }
    *   }
    * }
    */
  getHomePageProduct: async () => {
    const result = await createAxios().get('Product/HomePageTreeNode')
    return result.data
  },
  /**
    * @function getPopularTourProduct 商品 - 首頁【熱門遊程】 5X3 商品資訊
    * @param { number[] } params - 商品 id List
    *
    * // response 200 / 400 (data 內容 和 searchProduct 一樣)
    * @returns {
    *   {
    *      success: boolean,
    *      messages: [{
    *        errorCode: string,
    *        message: [{
    *          language: string,
    *          message: string
    *        }]
    *      }],
    *      data: [
    *        {
    *          productNumber: string      // 商品 id
    *          title: string,             // 文案主標題
    *          description: string        // 文案內容
    *          price: number,             // 價格
    *          minPrice: number,          // 最低價
    *          isCancelFree: boolean,     // 是否可免費取消
    *          isInstantBooking: boolean, // 及時預定 (即買即用)
    *          city: string,              // 城市名稱
    *          country: string,           // 國家名稱
    *          mediaPath: string[]        // 多媒體路徑
    *        }
    *      ]
    *   }
    * }
    */
  getPopularTourProduct: async (params) => {
    const result = await createAxios().post('Product/HotTop', params)
    return result.data
  },
  getRecommendProducts: async (quantity) => {
    const result = await createAxios().post('Product/GuessYouLike', quantity)
    return result.data
  },
}

export default product
