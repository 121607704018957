import {
  useState,
  useEffect,
  memo,
} from 'react'
import PropTypes from 'prop-types'
import classReader from 'utils/classReader'

const Dialog = (props) => {
  const {
    prompt,
    persistent,
    onClose,
    children,
  } = props

  const [animeControl, setAnimeControl] = useState(false)

  const handleBackdropClose = (e) => {
    if (persistent) return

    const targetClass = e?.target?.className
    const isClose = typeof targetClass === 'string' && targetClass.includes('backdrop')

    if (isClose) onClose(false)
  }

  useEffect(() => {
    const body = window.document.body

    if (prompt) {
      body.classList.add(classReader('overflow-hidden'))
      setAnimeControl(true)
    } else {
      setTimeout(() => setAnimeControl(false), 200)
    }

    return () => {
      body.classList.remove(classReader('overflow-hidden'))
    }
  }, [prompt])

  const dialogStatus = prompt ? 'open' : 'close'
  return (
    animeControl && (
      <div className={classReader('backdrop fullscreen', `backdrop--${dialogStatus}`)}>
        <div
          className={classReader('backdrop__content fixed-full d-flex flex-center')}
          onClick={handleBackdropClose}
        >
          {children}
        </div>
      </div>
    )
  )
}

Dialog.propTypes = {
  prompt: PropTypes.bool,
  persistent: PropTypes.bool,
  children: PropTypes.node,
  onClose: PropTypes.func,
}

Dialog.defaultProps = {
  prompt: false,
  persistent: false,
  onClose: () => { },
}

export default memo(Dialog)
