import CommonStyled from 'styles/utils/common/common.module.scss'

import GroupStyled from 'styles/pages/group.module.scss'
import IndexStyled from 'styles/pages/home/index.module.scss'
import KeyVisionStyled from 'styles/pages/home/key-vision.module.scss'
import TopRecommendLikesStyled from 'styles/pages/home/top-recommend-likes.module.scss'
import RecommendByKeywordStyled from 'styles/pages/home/recommend-by-keyword.module.scss'
import AircraftRunwayStyled from 'styles/pages/home/aircraft-runway.module.scss'
import PrivacyPolicyStyled from 'styles/pages/privacy-policy.module.scss'
import SearchStyled from 'styles/pages/search/search.module.scss'
import SiteMapStyled from 'styles/pages/sitemap.module.scss'
import UIStyled from 'styles/pages/ui.module.scss'

// account
import AccountStyled from 'styles/pages/account/index.module.scss'
import NoDataStyled from 'styles/pages/account/nodata.module.scss'
import OrderDetailStyled from 'styles/pages/account/order/order-detail.module.scss'
import OrderModalStyled from 'styles/pages/account/order/order-modal.module.scss'
import OrderStyled from 'styles/pages/account/order/order.module.scss'
import PayStyled from 'styles/pages/account/pay.module.scss'
import PointStyled from 'styles/pages/account/point.module.scss'
import PrecautionsStyled from 'styles/pages/account/precautions.module.scss'
import ProfileStyled from 'styles/pages/account/profile.module.scss'

// 404
import Custom404Styled from 'styles/pages/custom404.module.scss'

// cart
import CartStyled from 'styles/pages/cart/cart.module.scss'
import FormStyled from 'styles/pages/cart/form.module.scss'

// product
import ProductStyled from 'styles/pages/product/product.module.scss'
import ProductStyledV2 from 'styles/pages/product/product-v2.module.scss'

// layout
import RecommendLikesStyled from 'styles/layout/recommend-likes.module.scss'

// custom-service
import CustomServiceStyled from 'styles/pages/custom-service/customService.module.scss'

export const styleSwitch = {
  CommonStyled: CommonStyled,
  SearchStyled: SearchStyled,
  IndexStyled: IndexStyled,
  KeyVisionStyled: KeyVisionStyled,
  TopRecommendLikesStyled: TopRecommendLikesStyled,
  RecommendByKeywordStyled: RecommendByKeywordStyled,
  AircraftRunwayStyled: AircraftRunwayStyled,
  PrivacyPolicyStyled: PrivacyPolicyStyled,
  SiteMapStyled: SiteMapStyled,
  GroupStyled: GroupStyled,
  UIStyled: UIStyled,
  AccountStyled: AccountStyled,
  NoDataStyled: NoDataStyled,
  OrderStyled: OrderStyled,
  OrderDetailStyled: OrderDetailStyled,
  PayStyled: PayStyled,
  PrecautionsStyled: PrecautionsStyled,
  ProfileStyled: ProfileStyled,
  PointStyled: PointStyled,
  Custom404Styled: Custom404Styled,
  CartStyled: CartStyled,
  ProductStyled: ProductStyled,
  ProductStyledV2: ProductStyledV2,
  RecommendLikesStyled: RecommendLikesStyled,
  OrderModalStyled: OrderModalStyled,
  CustomServiceStyled: CustomServiceStyled,
  FormStyled: FormStyled,
}

export const styleList = {
  CommonStyled: 'CommonStyled',
  SearchStyled: 'SearchStyled',
  IndexStyled: 'IndexStyled',
  KeyVisionStyled: 'KeyVisionStyled',
  TopRecommendLikesStyled: 'TopRecommendLikesStyled',
  RecommendByKeywordStyled: 'RecommendByKeywordStyled',
  AircraftRunwayStyled: 'AircraftRunwayStyled',
  PrivacyPolicyStyled: 'PrivacyPolicyStyled',
  SiteMapStyled: 'SiteMapStyled',
  GroupStyled: 'GroupStyled',
  UIStyled: 'UIStyled',
  AccountStyled: 'AccountStyled',
  NoDataStyled: 'NoDataStyled',
  OrderStyled: 'OrderStyled',
  OrderDetailStyled: 'OrderDetailStyled',
  PayStyled: 'PayStyled',
  PrecautionsStyled: 'PrecautionsStyled',
  ProfileStyled: 'ProfileStyled',
  PointStyled: 'PointStyled',
  Custom404Styled: 'Custom404Styled',
  CartStyled: 'CartStyled',
  ProductStyled: 'ProductStyled',
  ProductStyledV2: 'ProductStyledV2',
  RecommendLikesStyled: 'RecommendLikesStyled',
  OrderModalStyled: 'OrderModalStyled',
  CustomServiceStyled: 'CustomServiceStyled',
  FormStyled: 'FormStyled',
}