import axios from 'axios'
import {
  ENV_INFO,
  APP_ID,
  LOCAL_STORAGE,
} from 'config/config'
import {
  ERROR_TYPE_CODE,
  ERROR_CODE_LABEL,
  OAUTH_ERROR_CODE_LABEL,
} from 'config/error'
import { localStorage } from 'utils/storage'
import { convertToCamelCase } from 'utils/util'

export const createAxios = (cancel) => {
  const instance = axios.create({
    baseURL: ENV_INFO.apiRoot,
    headers: {
      'Content-Type': 'application/json; charset=UTF-8',
      'APP_ID': APP_ID,
    },
  })

  instance.interceptors.request.use((request) => {
    // 請求前的共同行為
    const req = { ...request }

    if (cancel !== void 0) req.signal = cancel?.signal

    // 原本使用 redux-persist 時的寫法
    // const persistRoot = localStorage.getParse(LOCAL_STORAGE.PERSIST_ROOT)
    // const persist = jsonParse(persistRoot._persist)
    // if (persist !== null && !!persist.accessToken) {
    // 	req.headers['Authorization'] = `Bearer ${persist.accessToken}`
    // }

    // Client-side-only code
    if (typeof window !== 'undefined') {
      const token = localStorage.get(LOCAL_STORAGE.TOKEN)

      if (token !== null) {
        req.headers['Authorization'] = `Bearer ${token}`
      }
    }

    return req
  })

  instance.interceptors.response.use((res) => {
    // 回應後的共同行為

    return res
  },
  (err) => {
    // 錯誤返回後的共同行為
    const errorStatus = err.response?.status
    const errorData = err.response?.data
    let errorCode = ''
    let errorMsg = ''
    let originError = {}

    // 和泰 oAuth 報錯，errors => 和泰 oAuth api 才有的欄位
    if (!!errorData && errorData.errors !== undefined) {
      errorCode = Object.keys(errorData.errors)[0]
      errorMsg = OAUTH_ERROR_CODE_LABEL[errorCode]
      originError = errorData

      // ADJUST: EC 報錯，待優化
    } else if (!!errorData) {
      const originErrorCode = Array.isArray(errorData.messages) ? errorData.messages[0].errorCode : ''
      errorCode = ERROR_TYPE_CODE[originErrorCode]
      errorMsg = ERROR_CODE_LABEL[errorCode]
      originError = errorStatus === 400 ? convertToCamelCase(errorData) : errorData

    } else {
      errorCode = '9999'
      errorMsg = ''
      originError = err.response || err
    }

    const customResponse = {
      code: errorCode,
      status: errorStatus,
      message: errorMsg,
      originError: errorData,
    }

    throw customResponse
  })

  return instance
}