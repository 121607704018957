import { ENV_INFO } from 'config/config'

export const WEB_TITLE = '去趣chicTrip | 旅遊商城'
export const TAB_TITLE_SUFFIX = ` - ${WEB_TITLE}`

export const HEAD_DEFAULT_DATA = {
  TITLE: WEB_TITLE, 
  DESC: `${WEB_TITLE}推出全新旅遊產品，提供會員更多元的商品選擇。${WEB_TITLE}蒐羅全台知名景點、遊樂園、溫泉飯店及展覽活動，消費者可輕鬆於線上訂購門票；此外更精選人氣飯店下午茶、住宿券、SPA按摩等熱門票券，支援會員全方位生活服務。${WEB_TITLE}全站s商品皆可使用和泰Points點數，1點＝1元，無金額門檻限制，折抵無上限。`,
  URL: ENV_INFO.root,
  IMAGE: `${ENV_INFO.root}hotaigo.png`,
  TYPE: 'website',
}