import { memo } from 'react'
import PropTypes from 'prop-types'
import classReader from 'utils/classReader'

import Modal from 'common/Modal'
import Separator from 'common/Separator'
import CardSection from 'common/card/CardSection'
import Button from 'common/Button'
import { ERROR_CODE_LABEL } from 'config/error'

const ECModal = (props) => {
  const {
    id,
    className,
    prompt,
    title,
    cancelLabel,
    submitLabel,
    persistent,
    children,
    buttonChildren,
    cancel,
    submit,
    submitDisabled,
    onClose,
    onSubmit,
  } = props

  return (
    <Modal
      id={id}
      className={classReader('ec-modal', className)}
      prompt={prompt}
      title={title}
      onClose={onClose}
      persistent={persistent}
      separator
      close
    >
      <CardSection>
        {children
          ? children
          : (
            <>
              <p className={classReader('text-center m-0 mb-3')}>抱歉</p>
              <p className={classReader('text-center m-0')}>{ERROR_CODE_LABEL[9999]}</p>
            </>
          )}
      </CardSection>

      {(cancel || submit || Boolean(buttonChildren)) && (
        <>
          <Separator color="gray-2" />

          <CardSection className={classReader('ec-modal__buttons')}>
            {/* buttonChildren 如果有兩個 button，第一個記得要加 className：ec-modal__action--cancel_submit */}
            {buttonChildren}

            <div className={classReader('ec-modal__action')}>
              {cancel && (
                <Button
                  className={classReader('ec-modal__action--cancel w-full', { 'ec-modal__action--cancel_submit': cancel && submit })}
                  label={cancelLabel}
                  textColor="gray-4"
                  onClick={() => onClose(false)}
                  outline
                  unelevated
                />
              )}

              {submit && (
                <Button
                  className={classReader('ec-modal__action--submit w-full')}
                  label={submitLabel}
                  color="primary"
                  textColor="white"
                  onClick={() => onSubmit(false)}
                  disabled={submitDisabled}
                  unelevated
                />
              )}
            </div>
          </CardSection>
        </>
      )}
    </Modal>
  )
}

ECModal.propTypes = {
  id: PropTypes.string,
  className: PropTypes.string,
  prompt: PropTypes.bool,
  title: PropTypes.string,
  cancelLabel: PropTypes.string,
  submitLabel: PropTypes.string,
  persistent: PropTypes.bool,
  cancel: PropTypes.bool,
  submit: PropTypes.bool,
  submitDisabled: PropTypes.bool,
  children: PropTypes.node,
  buttonChildren: PropTypes.node,
  onClose: PropTypes.func,
  onSubmit: PropTypes.func,
}

ECModal.defaultProps = {
  prompt: false,
  title: '系統提示',
  cancelLabel: '取消',
  submitLabel: '確定',
  cancel: false,
  submit: false,
  submitDisabled: false,
  persistent: false,
  onClose: () => { },
  onSubmit: () => { },
}

export default memo(ECModal)
