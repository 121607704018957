import { useRouter } from 'next/router'
import PropTypes from 'prop-types'
import { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'

import Footer from 'common/layout/Footer'
import Header from 'common/layout/Header'
import ScrollUp from 'common/layout/ScrollUp'
import Loading from 'common/Loading'
import ECModal from 'common/layout/ECModal'
import PinnedAnnouncement from 'common/layout/PinnedAnnouncement'

import { SESSION_STORAGE } from 'config/config'
import { WEBSITE_MAINTENANCE_DATE } from 'config/date'
import { REDIRECT_ERROR_CODE } from 'config/error'
import { FOOTER_COMPANY_INFO } from 'config/footer'
import { NOTICE_DATA, VERIFIED_DATA } from 'config/page/home'

import { resetAuthSetting } from 'slices/auth/authSlice'
import { selectIsLoading, selectLoadingText } from 'slices/loadingSlice'
import {
  resetErrorState,
  selectErrorCode,
  selectErrorMessage,
} from 'slices/errorSlice'

import classReader from 'utils/classReader'
import { handleTimeAdjustment } from 'utils/date'
import { localStorage, sessionStorage } from 'utils/storage'
import { ecErrorCode } from 'utils/util'

import AntiFraud from 'page-components/home/AntiFraud'

const Layout = (props) => {
  const { children } = props

  const router = useRouter()
  const dispatch = useDispatch()
  const isLoading = useSelector(selectIsLoading)
  const loadingText = useSelector(selectLoadingText)
  const errorCode = useSelector(selectErrorCode)
  const errorMessage = useSelector(selectErrorMessage)

  const [isOpenErrorModal, setIsOpenErrorModal] = useState(false)
  const [announcementStatus, setAnnouncementStatus] = useState(false)

  const [antiFraudPrompt, setAntiFraudPrompt] = useState(false)

  const handleAnnouncement = () => {
    // 置頂維修公告
    const isAnnouncementTime = handleTimeAdjustment(
      WEBSITE_MAINTENANCE_DATE.start,
      WEBSITE_MAINTENANCE_DATE.end,
      WEBSITE_MAINTENANCE_DATE.showTimeAdjust.start,
      WEBSITE_MAINTENANCE_DATE.showTimeAdjust.end,
    )

    if (isAnnouncementTime && WEBSITE_MAINTENANCE_DATE.start && WEBSITE_MAINTENANCE_DATE.end) {
      setAnnouncementStatus(true)
    } else {
      setAnnouncementStatus(false)
    }

    if (WEBSITE_MAINTENANCE_DATE.type === 'MAINT' && router.asPath !== '/maintenance'){
      // 進入維修畫面
      const isMaintenanceTime = handleTimeAdjustment(
        WEBSITE_MAINTENANCE_DATE.start,
        WEBSITE_MAINTENANCE_DATE.end,
        WEBSITE_MAINTENANCE_DATE.blockTimeAdjust.start,
        WEBSITE_MAINTENANCE_DATE.blockTimeAdjust.end,
      )
      if (isMaintenanceTime && WEBSITE_MAINTENANCE_DATE.start && WEBSITE_MAINTENANCE_DATE.end){
        router.push('/maintenance')
      }
    }
  }

  const closeErrorModal = () => {
    setIsOpenErrorModal(false)
    dispatch(resetErrorState())

    if (router.pathname.includes('/product')) {
      // 清除【按下加入購物車】時，暫存的商品內容
      sessionStorage.clear() 
    } else if (router.pathname === '/') {
      // 首頁發生錯誤 => 87% header 的 api 出事，因此清空所有 redux 值
      localStorage.clearExpectAntiFraud()
      dispatch(resetAuthSetting())
    } else {
      router.push('/')
    }
  }

  const handleAntiFraudClose = () => {
    setAntiFraudPrompt(prev => !prev)
  }
  
  useEffect(() => {
    if (
      WEBSITE_MAINTENANCE_DATE?.isActive &&
      WEBSITE_MAINTENANCE_DATE?.start && 
      WEBSITE_MAINTENANCE_DATE?.end
    ) handleAnnouncement()

    /* 清除 session 商品資訊(Jason)
     * 說明 : 
     * 在用戶點選商品加入購物車時
     * 如果後端回傳 code 符合 config/error > REFRESH_TOKEN_ERROR_CODE 中列舉
     * 會先將商品資訊(含商品頁面 productNumber 及 購買方案) 存入 session
     * 等待登入成功後，觸發自動跳轉與自動重新幫用戶加入購物車，才會將其資料刪除
     * 
     * 單元暹羅集中，並未考量到在用戶瞬間點選其餘畫面時，造成在以下情境
     * 1. 登入未完成，直接點選其餘畫面(如購物車、其他商品、搜尋...等)
     * 2. 登入成功，導回首頁，等待登入成功驗證，尚未觸發頁面跳轉與加入購物車時，用戶直接點選其餘畫面(如購物車、其他商品、搜尋...等)
     * 以上情境操作下，將導至登入失敗，且進入隨意商品時，自動觸發加入購物車，從而導致錯誤
     * 
     * 因此，此處針對該情境進行處理，規範商品 session 資訊只在允許條件下存在
     * 非以下條件，將清除 session 商品資訊
     * 1. 首頁
     * 2. 原商品頁
     * 3. 404 (因目前有機率自動先跳轉404，問題待查)
     * 
    */

    const pathnameData = location?.pathname?.split('/') || ['', '']
    if (![
      '', // 首頁
      'product', // 商品頁
      '404', // 404
    ].includes(pathnameData[1])) {
      sessionStorage.remove(SESSION_STORAGE.STASH_CART)
      sessionStorage.remove(SESSION_STORAGE.STASH_PATH_NAME)
    }

    /* 首頁資訊遮罩，用以提醒用戶目前正在處理資料(待撰寫)
     * 說明 : 
     * 新增 首頁資訊遮罩，用以提醒用戶目前正在處理資料，目前手頁需處理以下資訊
     * 1. OAuth 登入成功/失敗，返回首頁打 API 確認登入成功與否時
     * 2. 登入前點選加入購物車，回來確認登入成功後，在跳轉商品頁前
    */

  }, [router])

  useEffect(() => {
    if (!!errorMessage) setIsOpenErrorModal(true)
  }, [errorMessage])

  useEffect(() => {
    if (Boolean(errorCode) && REDIRECT_ERROR_CODE.includes(errorCode) === false) {
      dispatch({ type: 'loading/closeLoading' })
    }
    /* ignore dependency: dispatch */
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [errorCode])

  return (
    <>
      {announcementStatus && 
        <PinnedAnnouncement
          startDate={WEBSITE_MAINTENANCE_DATE.start}
          endDate={WEBSITE_MAINTENANCE_DATE.end}
          blockTimeAdjust={WEBSITE_MAINTENANCE_DATE.blockTimeAdjust}
          text={WEBSITE_MAINTENANCE_DATE.text}
          type={WEBSITE_MAINTENANCE_DATE.type}
        />
      }

      <div className={classReader('--view')}>
        <div className={classReader('layout__content')}>
          <Header />
          {children}
          <Footer />
        </div>

        <ScrollUp handleAntiFraudClose={handleAntiFraudClose}/>
        {/* 有點影響刻劃面，先註解起來 */}
        {/* <Cookie /> */}
      </div>

      <ECModal
        prompt={isOpenErrorModal}
        title="系統提示"
        submitLabel="確定"
        onSubmit={closeErrorModal}
        onClose={closeErrorModal}
        submit
      >
        {errorCode != 9999 && <>
          <div className={classReader('text-weight-bold text-center')}>
            <p>{errorMessage}</p>
            <small>{ecErrorCode()}</small>
          </div>
        </>}
      </ECModal>

      <AntiFraud 
        NOTICE_DATA={NOTICE_DATA}
        VERIFIED_DATA={VERIFIED_DATA}
        FOOTER_COMPANY_INFO={FOOTER_COMPANY_INFO}
        prompt={antiFraudPrompt}
        onClose={handleAntiFraudClose}
      />

      <Loading isLoading={isLoading} loadingText={loadingText} />
    </>
  )
}

Layout.propTypes = { children: PropTypes.node }

export default Layout