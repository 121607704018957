export const searchHistory = [
  {
    id: 1,
    name: '環球門票',
    link: '#',
  },
  {
    id: 2,
    name: '很長的關鍵字',
    link: '#',
  },
]

export const HOT_ADDRESS = [
  {
    id: 1,
    name: '東京',
    link: '#',
  },
  {
    id: 1,
    name: '大阪',
    link: '#',
  },
  {
    id: 1,
    name: '沖繩',
    link: '#',
  },
  {
    id: 1,
    name: '首爾',
    link: '#',
  },
  {
    id: 1,
    name: '台北',
    link: '#',
  },
  {
    id: 1,
    name: '大阪',
    link: '#',
  },
  {
    id: 1,
    name: '台中',
    link: '#',
  },
  {
    id: 1,
    name: '京都',
    link: '#',
  },
  {
    id: 1,
    name: '桃園',
    link: '#',
  },
  {
    id: 1,
    name: '新竹',
    link: '#',
  },
  {
    id: 1,
    name: '澎湖',
    link: '#',
  },
  {
    id: 1,
    name: '台南',
    link: '#',
  },
  {
    id: 1,
    name: '高雄',
    link: '#',
  },
  {
    id: 1,
    name: '新北市',
    link: '#',
  },
  {
    id: 1,
    name: '曼谷',
    link: '#',
  },
]

export const PLACEHOLDER = '輸入目的地、景點、體驗行程或活動名稱...'