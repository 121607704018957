import { createAxios } from 'apis/axios'

const member = {
  /**
   * @function getMemberInfo EC 會員 - 透過和泰集團會員 Token 登入取得相關會員資料 (隱碼)
   * 
   * // response 200 / 400
   * @returns {
   *   {
   *      success: boolean,
   *      messages: [{
   *        errorCode: string,
   *        message: [{
   *          language: string,
   *          message: string
   *        }]
   *      }],
   *      data: {
   *        name: string,
   *        appellation: string,   // 稱謂
   *        email: string,
   *        phone: string
   *      } || null
   *   }
   * }
   */
  getMemberInfo: async () => {
    const result = await createAxios().get('Member/Hide')
    return result.data
  },
  /**
   * @function memberInfoRefresh EC 會員 - 和泰 OAuth 更新回來後刷新會員資料 (隱碼)
   * 
   * // response 200 / 400
   * @returns {
  *   {
  *      success: boolean,
  *      messages: [{
  *        errorCode: string,
  *        message: [{
  *          language: string,
  *          message: string
  *        }]
  *      }],
  *      data: {
  *        name: string,
  *        appellation: string,   // 稱謂
  *        email: string,
  *        phone: string,
  *        birthday: date-time
  *      } || null
  *   }
  * }
  */
  memberInfoRefresh: async () => {
    const result = await createAxios().get('Member/Refresh/Hide')
    return result.data
  },
  /**
   * @async
   * @function getTravelerInfo EC 會員 - 取得旅客資訊
   * 
   * // response 200 / 400
   * @returns {
   *   {
   *      success: boolean,
   *      messages: [{
   *        errorCode: string,
   *        message: [{
   *          language: string,
   *          message: string
   *        }]
   *      }],
   *      data: {
   *        surname: string,         // 姓氏
   *        givenName: string,       // 名稱
   *        passportNo: string,      // 護照號碼
   *        dateOfBirth: date-time,  // 生日
   *        nationality: string      // 國籍
   *      } || null
   *   }
   * }
   */
  getTravelerInfo: async () => {
    const result = await createAxios().get('Member/TravelerInfo')
    return result.data
  },
  /**
   * @function updateTravelerInfo EC 會員 - 旅客資訊更新
   * @param { Object } params
   * @param { string } params.surname - 姓氏
   * @param { string } params.givenName - 名稱
   * @param { string } params.passportNo - 護照號碼
   * @param { string } params.dateOfBirth - 生日 ( yyyy-MM-dd )
   * @param { string } params.nationality - 國籍
   * 
   * // response 200 / 400
   * @returns {
   *   {
   *      success: boolean,
   *      messages: [{
   *        errorCode: string,
   *        message: [{
   *          language: string,
   *          message: string
   *        }]
   *      }]
   *   }
   * }
   */
  updateTravelerInfo: async (params) => {
    const result = await createAxios().post('Member/TravelerInfo', params)
    return result.data
  },
}

export default member
