import PropTypes from 'prop-types'
import Link from 'next/link'

import classReader from 'utils/classReader'
import { moneyFormat } from 'utils/util'

import Button from 'common/Button'
import Separator from 'common/Separator'

const Menu = (props) => {
  const {
    navAccount,
    navMenu,
    isLoggedIn,
    userData,
    onToggleMenu,
    onHandleLogin,
    onHandleLogout,
    onExchangeOut,
    setIsDialog,
  } = props

  return (
    <div className={classReader('mobile-menu')}>
      {isLoggedIn === true ? (
        <div className={classReader('mobile-menu__content')}>
          <div className={classReader('d-flex align-items-center mb-5')}>
            <div className={classReader('mobile-menu__user-img bg-light-primary d-flex align-items-center justify-content-center')}>
              <i className={classReader('icon icon-user-full icon-primary')} />
            </div>
            <div className={classReader('pr-3 pl-3')}>
              <h3 className={classReader('m-0 mb-2')}>{userData.name}</h3>
              <div className={classReader('d-flex align-items-center')}>
                <i className={classReader('icon icon-point icon-white bg-red rounded-100 icon-sm')} />
                <span className={classReader('text-red ml-2 mr-2')}>{moneyFormat(userData.point)}</span>點
              </div>
            </div>
          </div>
          <div className={classReader('row')}>
            {navAccount.map((item, index) => item.disabled === false && (
              <div key={index} className={classReader('col-6 p-0 mt-2 mb-2')}>
                <Link
                  prefetch={false}
                  className={classReader('d-flex align-items-center', { pure: 'gtm-mobile-header-menu' })}
                  href={item.link}
                  target={item.target}
                  rel={item.target === '_blank' ? 'noreferrer noopenner' : ''}
                  onClick={onToggleMenu}
                  aria-label={item.label}
                  passHref
                >
                  <i className={classReader(`icon icon-${item.icon} icon-primary icon-sm mr-3`)} />
                  {item.label}
                </Link>
              </div>
            ))}
          </div>
        </div>
      ) : (
        <div className={classReader('mobile-menu__content')}>
          <div className={classReader('pb-3')}>註冊帳戶，享會員商品優惠</div>
          <Button
            className={classReader('mobile-menu__btn text-white', { pure: 'gtm-mobile-menu-login' })}
            label="去趣會員登入/註冊"
            icon="user-full"
            color="primary"
            iconColor="white"
            onClick={onHandleLogin}
            unelevated
          />
        </div>
      )}

      <Separator color="gray-2" className={classReader('my-4')} />

      
      {navMenu.map((item, index) => (
        <section key={`menu-content-${index}`}>
          <div className={classReader('mobile-menu__content')}>
            <div className={classReader('h4')}>{item.title}</div>
            <nav className={classReader('row')}>
              {item.option.map((menu, menuIndex) => 
                <div key={`menu-${menuIndex}`} className={classReader('col-6 pl-0 mt-2 mb-2')}>
                  <Link
                    className={classReader({ pure: 'gtm-mobile-header-menu' })}
                    prefetch={false}
                    href={menu.link}
                    target={menu.target}
                    rel={menu.target === '_blank' ? 'noreferrer noopenner' : ''}
                    onClick={onToggleMenu}
                    aria-label={menu.name}
                    passHref
                  >
                    {menu.name}
                  </Link>
                </div>)}
            </nav>
          </div>
          
          <Separator color="gray-2" className={classReader('my-4')} />
        </section>
      ))}

      <div className={classReader('mobile-menu__content')}>
        <div className={classReader('row')}>
          <div className={classReader('col-6 pl-0 mt-2 mb-2 d-flex align-items-center cursor-pointer', { pure: 'gtm-mobile-header-sub-menu' })} onClick={() => setIsDialog(prev => !prev)}>
            <i className={classReader('icon icon-cut-chicTrip mr-3')} /> 旅遊規劃必備APP
          </div>
          {/* <div className={classReader('col-6 pl-0 mt-2 mb-2')}>
            <div className={classReader('d-flex align-items-center cursor-pointer', { pure: 'gtm-mobile-header-sub-menu' })} onClick={onExchangeOut}>
              <i className={classReader('icon icon-cut-hotaigo mr-3')} />回到 <span>HOTAI 購</span>
            </div>
          </div> */}
          {/* <div className={classReader('col-6 pl-0 mt-2 mb-2 d-flex align-items-center')}>
            <i className={classReader('icon icon-email icon-primary mr-3')} />聯絡我們
          </div> */}

          {isLoggedIn === true && (
            <div
              className={classReader('col-6 pl-0 mt-2 mb-2 d-flex align-items-center cursor-pointer', { pure: 'gtm-mobile-header-sub-menu' })}
              onClick={onHandleLogout}
            >
              <i className={classReader('icon icon-logout icon-primary mr-3')} />登出
            </div>
          )}
        </div>
      </div>
    </div>
  )
}

Menu.propTypes = {
  navAccount: PropTypes.array,
  navMenu: PropTypes.array,
  userData: PropTypes.object,
  isLoggedIn: PropTypes.bool,
  onToggleMenu: PropTypes.func,
  onHandleLogin: PropTypes.func,
  onHandleLogout: PropTypes.func,
  onExchangeOut: PropTypes.func,
  setIsDialog: PropTypes.func,
}


Menu.defaultProps = {
  isLoggedIn: false,
  onToggleMenu: () => { },
  onHandleLogin: () => { },
  onHandleLogout: () => { },
  onExchangeOut: () => { },
}


export default Menu