import { createAxios } from 'apis/axios'

const point = {
  /**
   * @function searchPoints 和泰點數 - 會員點數查詢
   * @param { Object } params
   * @param { string } params.startDate - 明細資料查詢起日，最大查詢區間為 90天
   * @param { string } params.endDate - 明細資料查詢迄日，最大查詢區間為 90天
   * @param { number } params.queryType - 查詢類別。 1：總點數 2：總點數 +點數明細 3：總點數 +累點交易明細 4：總點數 +兌點交易明細
   * @param { number } params.pageSize - 明細分頁參數一頁幾筆資料。可接受數字10、 20、 30、 40、 50、 100。 若【 queryType】 為 2，則此欄位必填。
   * @param { number } params.pageIndex - 明細分頁參數第幾頁。 若【 queryType】 為 2，則此欄位必填。
   * 
   * // response 200 / 400
   * @returns {
   *   {
   *      success: boolean,
   *      messages: [{
   *        errorCode: string,
   *        message: [{
   *          language: string,
   *          message: string
   *        }]
   *      }],
   *      data: {
   *        point: number,              // 會員帳號目前有效總數 (不受查詢起迄日影響)
   *        expirePoint: number,        // 當月即將到期點數
   *        expireDate: string,         // 當月即將到期日期 (yyyyMMdd)
   *        expirePointByYear: number,  // 年度即將到期點數。 *當會員為自然人，才顯示
   *        expireDateByYear: string,   // 年度即將到期日期 (yyyyMMdd)
   *        detail: [
   *          {
   *            buName: string,         // 特約商名稱
   *            rsName: string,         // 據點 (門市) 名稱
   *            transDate: string,      // 交易日期 (yyyyMMdd)
   *            transTime: string,      // 交易時間 (HHmmss)
   *            transDesc: string,      // 交易備註
   *            amt: number,            // 支付金額
   *            addPoint: number,       // 交易累點數
   *            deductionPoint: number, // 交易兌 (扣) 點數
   *            moveIn: number,         // 轉入點數
   *            moveOut: number,        // 轉出點數
   *            outMemberAccount: string, // 轉出點數的會員帳號。 (會員帳號 5~7碼需為隱碼)
   *            inMemberAccount: string,  // 轉入點數的會員帳號。 (會員帳號 5~7碼需為隱碼)
   *            pointExpireDate: string   // 該交易的累點點數效期 yyyyMMdd (交易類型為累點時才會有值)
   *          }
   *        ]
   *      } || null
   *   }
   * }
   */
  searchPoints: async (params) => {
    const result = await createAxios().post('Points/SearchPoints', params)
    return result.data
  },
  /**
   * @function pointTransInfo 和泰點數 - 交易資訊。 此為系統設定資訊，說明單筆交易可折抵上限。
   * 
   * // response 200 / 400
   * @returns {
   *   {
   *      success: boolean,
   *      messages: [{
   *        errorCode: string,
   *        message: [{
   *          language: string,
   *          message: string
   *        }]
   *      }],
   *      data: {
   *        message: string,
   *        point: number,
   *        discountLimit: number
   *      } || null
   *   }
   * }
   */
  pointTransInfo: async () => {
    const result = await createAxios().post('Points/TransInfo')
    return result.data
  },
}

export default point
