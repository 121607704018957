import { combineReducers } from '@reduxjs/toolkit'

import { authSlice } from 'slices/auth/authSlice'
import { localAuthSlice } from 'slices/auth/localAuthSlice' // NOTE: for local login
import { memberSlice } from 'slices/auth/memberSlice'
import { oAuthSlice } from 'slices/hotai/oAuthSlice'
import { pointSlice } from 'slices/hotai/pointSlice'
import { cartSlice } from 'slices/product/cartSlice'
import { loadingSlice } from 'slices/loadingSlice'
import { errorSlice } from 'slices/errorSlice'

const rootReducer = combineReducers({
  [authSlice.name]: authSlice.reducer,
  [localAuthSlice.name]: localAuthSlice.reducer, // NOTE: for local login
  [memberSlice.name]: memberSlice.reducer,
  [oAuthSlice.name]: oAuthSlice.reducer,
  [pointSlice.name]: pointSlice.reducer,
  [cartSlice.name]: cartSlice.reducer,
  [loadingSlice.name]: loadingSlice.reducer,
  [errorSlice.name]: errorSlice.reducer,
})

// 需要暫存的資料 (搭配 redux-persist)
// export const persistWhiteList = [authSlice.name]
// 不需要暫存的資料 (搭配 redux-persist)
// export const persistBlackList = [
//   oAuthSlice.name,
//   pointSlice.name,
//   loadingSlice.name,
//   errorSlice.name
// ]

export default rootReducer
