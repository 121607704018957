import { DATETIME_FORMAT, DATE_FORMAT } from 'config/date'
import { handleDateFormat } from 'utils/date'

// account menu
export const ACCOUNT_MENU_LIST = [
  {
    label: '訂單管理',
    icon: 'fact-check',
    link: '/account/order',
    target: '_self',
    disabled: false,
    hidden: false,
  },
  {
    label: '付款設定',
    icon: 'card-check',
    link: '/account/pay',
    target: '_self',
    disabled: false,
    hidden: false,
  },
  {
    label: '和泰Points',
    icon: 'point icon-white bg-red rounded-100',
    link: '/account/point',
    target: '_self',
    disabled: false,
    hidden: false,
  },
  {
    label: '帳號設定',
    icon: 'settings',
    link: '/account/profile',
    target: '_self',
    disabled: false,
    hidden: false,
  },
  {
    // phase2
    label: '最近瀏覽',
    icon: 'add-comment',
    link: '/account/history',
    target: '_self',
    disabled: true,
    hidden: true,
  },
  {
    // phase2
    label: '我的收藏',
    icon: 'favorite-full',
    link: '/account/favorite',
    target: '_self',
    disabled: true,
    hidden: true,
  },
  {
    label: '和泰集團相關服務',
    icon: 'info-full',
    link: '/account/group',
    target: '_self',
    disabled: false,
    hidden: false,
  },
  {
    label: '常見問題',
    icon: 'help-full',
    link: 'https://www.hotaimember.com.tw/FAQ/list',
    target: '_blank',
    disabled: false,
    hidden: false,
  },
]

// #region pay
export const CARD_BIND_SUCCESS = ['I0000', '0000']
export const FAST_BIND_FAIL = 'OB007'
// #endregion pay

// #region point
export const POINT_RECORD_TAB_OPTIONS = [
  {
    name: 3,
    label: '累點',
    id: 'account-point-record-accumulated',
  },
  {
    name: 4,
    label: '兌點',
    id: 'account-point-record-exchange',
  },
]

export const POINT_TAB_OPTIONS = [
  {
    name: 'record',
    label: '點數記錄',
    id: 'account-point-record',
  },
  // {
  //   name: 'transfer',
  //   label: '點數移轉',
  //   id: 'account-point-transfer',
  //   disabled: true
  // }
]

export const RECORD_INFO_COLUMNS = [
  {
    label: '消費金額',
    name: 'amt',
    value: 'amt',
  },
  {
    label: '點數到期日',
    name: 'pointExpireDate',
    value: 'pointExpireDate',
  },
  {
    label: '轉入會員帳號',
    name: 'inMemberAccount',
    value: 'inMemberAccount',
  },
  {
    label: '轉出會員帳號',
    name: 'outMemberAccount',
    value: 'outMemberAccount',
  },
  {
    label: '備註',
    name: 'transDesc',
    value: 'transDesc',
  },
]
// #endregion point

// #region order
/* FIXME: 待跟後端確認 處理中跟已完成的 商品 status */
export const ORDER_TAB_OPTIONS = [
  {
    name: 'UNPAY',
    label: '待付款',
    type: [
      'QUOTATION',
      'OK',
      'WAITING',
      'PARTIAL_OFF',
    ],
    childType: [],
    id: 'account-order-accumulated',
  },
  {
    name: 'PROCESSING',
    label: '處理中',
    type: [],
    childType: ['PROCESSING'],
    id: 'account-order-processing',
  },
  {
    name: 'VOUCHER',
    label: '已完成',
    type: ['WRITE_OFF'],
    childType: ['COMPLETE'],
    id: 'account-order-completed',
  },
  {
    name: 'CANCEL',
    label: '已取消/取消中',
    type: ['CANCEL', 'REFUND'],
    childType: [
      'CANCELING',
      'WAITING_REFUND',
      'CANCEL',
      'REFUND',
    ],
    id: 'account-order-cancel',
  },
]

/* key value by api */
export const ORDER_STATUS_MAP = {
  'QUOTATION': {
    opLabel: '報價中',
    userLabel: '未付款',
  },
  'OK': {
    opLabel: '報價中',
    userLabel: '未付款',
  },
  'WAITING': {
    opLabel: '待補位',
    userLabel: '未付款',
  },
  'PARTIAL_OFF': {
    opLabel: '部分付款',
    userLabel: '未付款',
  },
  'WRITE_OFF': {
    opLabel: '付款完成',
    userLabel: '已完成',
  },
  'CANCEL': {
    opLabel: '已取消',
    userLabel: '已取消/取消中',
  },
  'REFUND': {
    opLabel: '已銷退',
    userLabel: '已取消/取消中',
  },
  'FINISH': {
    opLabel: '訂單錯誤(結束)',
    userLabel: '-',
  },
  'CLOSE': {
    opLabel: '訂單結算',
    userLabel: '-',
  },
}

/* key value by api */
export const PRODUCT_STATUS_MAP = {
  'PROCESSING': {
    value: 'PROCESSING',
    opLabel: '處理中',
    userLabel: '處理中',
    hasRefundButton: true,
    hasDownloadButton: true,
    canDownload: false,
    downloadButtonLabel: '尚未發送憑證',
  },
  'COMPLETE': {
    value: 'COMPLETE',
    opLabel: '已完成',
    userLabel: '已完成',
    hasRefundButton: true,
    hasDownloadButton: true,
    canDownload: true,
    downloadButtonLabel: '寄送憑證',
  },
  'CANCELING': {
    value: 'CANCELING',
    opLabel: '取消中',
    userLabel: '取消中',
    hasRefundButton: false,
    hasDownloadButton: false,
    canDownload: false,
    downloadButtonLabel: '',
  },
  'WAITING_REFUND': {
    value: 'WAITING_REFUND',
    opLabel: '待退款',
    userLabel: '取消中',
    hasRefundButton: false,
    hasDownloadButton: false,
    canDownload: false,
    downloadButtonLabel: '',
  },
  'CANCEL': {
    value: 'CANCEL',
    opLabel: '已取消',
    userLabel: '已取消',
    hasRefundButton: false,
    hasDownloadButton: false,
    canDownload: false,
    downloadButtonLabel: '',
  },
  'REFUND': {
    value: 'REFUND',
    opLabel: '已退款',
    userLabel: '已退款',
    hasRefundButton: false,
    hasDownloadButton: false,
    canDownload: false,
    downloadButtonLabel: '',
  },
}

export const ORDER_DETAIL_COLUMNS_OLD = [
  {
    label: '方案名稱',
    name: 'packageName',
    value: (detail) => detail.packageName || '-',
    column: 1,
  },
  {
    label: '規格',
    name: 'quantity',
    value: (detail) => {
      const orderDetailList = detail?.orderDetailItem || []
      return `不拘 * ${detail.quantity} ${detail.unit || '張'}`
    },
    column: 1,
  },
]

export const ORDER_DETAIL_COLUMNS = [
  {
    label: '方案名稱',
    name: 'packageName',
    value: (detail) => detail.packageName || '-',
    column: 1,
  },
  {
    label: '出發日期',
    name: 'targetDate',
    value: (detail) => handleDateFormat(detail.targetDate, DATE_FORMAT) || '-',
    column: 1,
  },
]

export const USER_DETAIL_COLUMNS = [
  {
    label: '訂購人姓名',
    name: 'orderUserName',
    value: (detail) => `${detail.buyerLastName || ''}${detail.buyerFirstName || ''}` || '-',
    column: 1,
  },
  {
    label: '聯絡電話',
    name: 'orderUserPhone',
    value: (detail) => detail.buyerPhone || '-',
    column: 1,
  },
  {
    label: '電子郵件',
    name: 'orderUserEmail',
    value: (detail) => detail.buyerEmail || '-',
    column: 1,
  },
  {
    label: '訂購日期',
    name: 'createdTime',
    value: (detail) => handleDateFormat(detail.createdTime, DATETIME_FORMAT) || '-',
    column: 1,
  },
]

export const INVOICE_DETAIL_COLUMNS = [
  {
    label: '代轉號碼',
    name: 'invoiceNumber',
    value: (detail) => detail.invoiceNumber || '-',
    column: 1,
  },
  {
    label: '公司行號抬頭',
    name: 'invoiceBuyer',
    value: (detail) => detail.invoiceBuyer || '-',
    column: 1,
  },
  {
    label: '開立日期',
    name: 'invoiceTime',
    value: (detail) => handleDateFormat(detail.invoiceTime, DATETIME_FORMAT) || '-',
    column: 1,
  },
]

export const CANCEL_DETAIL_COLUMNS = [
  {
    label: '取消日期',
    name: 'cancelDate',
    value: (cancel) => handleDateFormat(cancel.cancelDate) || '-',
    column: 1,
  },
  {
    label: '取消原因',
    name: 'cancelReason',
    value: (cancel) => cancel.cancelReason || '-',
    column: 1,
  },
  {
    label: '取消備註',
    name: 'cancelRemark',
    value: (cancel) => cancel.cancelRemark || '-',
    column: 2,
  },
]

export const ORDER_DETAIL_TABS_INFO = [
  {
    name: 'orderInfo',
    label: '訂購資訊',
  },
  {
    name: 'refundPolicy',
    label: '取消政策',
  },
]
// #endregion order
