import { memo } from 'react'
import PropTypes from 'prop-types'
import classReader from 'utils/classReader'
import ReactLoading from 'react-loading'

import Dialog from 'common/Dialog'

import { DEFAULT_SIZES, COLOR_MAPPING } from 'config/style'

const Loading = (props) => {
  const {
    isLoading,
    loadingText,
    textClassName,
  } = props

  return (
    isLoading && (
      <Dialog prompt persistent>
        <div className={classReader('text-center')}>
          <ReactLoading
            className={classReader('m-auto')}
            type="spin"
            color={COLOR_MAPPING.light}
            height={DEFAULT_SIZES.md * 2.5}
            width={DEFAULT_SIZES.md * 2.5}
          />

          {Boolean(loadingText) && (
            <div className={classReader('text-white text-weight-bold text-lg mt-3', textClassName)}>
              {loadingText}
            </div>
          )}
        </div>
      </Dialog>
    )
  )
}

Loading.propTypes = {
  isLoading: PropTypes.bool,
  loadingText: PropTypes.string,
  textClassName: PropTypes.string,
}

Loading.defaultProps = { isLoading: false }

export default memo(Loading)
