import {
  useEffect,
  useState,
  useCallback,
} from 'react'
import { useRouter } from 'next/router'
import { useDispatch, useSelector } from 'react-redux'
import {
  IS_DEVELOPMENT_ENV,
  LOCAL_STORAGE,
  SESSION_STORAGE,
  AUTH_PAGE,
  ENV_INFO, 
  SHOW_CONSOLE,
} from 'config/config'
import { localStorage, sessionStorage } from 'utils/storage'
import auth from 'apis/auth/auth'
import {
  ecSignOut,
  ecVerifyToken,
  ecRefreshToken,
  resetAuthSetting,
  setAccessTokenState,
  setCanGetInitialData,
  selectAccessToken,
  selectCanGetInitialData,
} from 'slices/auth/authSlice'
import { ecLocalSignIn } from 'slices/auth/localAuthSlice'
import { getMemberInfo, selectName } from 'slices/auth/memberSlice'
import { oAuthSignIn } from 'slices/hotai/oAuthSlice'
import { searchTotalPoints, selectHotaiPoint } from 'slices/hotai/pointSlice'
import { getCartCount, selectCartAmount } from 'slices/product/cartSlice'
import { selectHasRefreshToken, selectHasRedirectOAuthLogin } from 'slices/errorSlice'
import { cookieExpires } from 'utils/date'

export const useAuth = () => {
  const router = useRouter()
  const dispatch = useDispatch()
  const accessToken = useSelector(selectAccessToken)
  const userName = useSelector(selectName)
  const userHotaiPoint = useSelector(selectHotaiPoint)
  const userCartAmount = useSelector(selectCartAmount)
  const hasRefreshToken = useSelector(selectHasRefreshToken)
  const canGetInitialData = useSelector(selectCanGetInitialData)
  const hasRedirectOAuthLogin = useSelector(selectHasRedirectOAuthLogin)

  const [isTokenVerifying, setIsTokenVerifying] = useState(false) // 正在呼叫 驗證 / 更新 token api 中 (防止重複呼叫用)
  const [isLoggingOut, setIsLoggingOut] = useState(false) // 正在進登出中 (防止登出後回首頁前判定到要跳轉到登入頁)
  const [isExchanging, setIsExchanging] = useState(false) // 跳轉過來時不讓畫面看起來是靜止的

  // after login / verify token / refresh token 
  const getUserData = async () => {
    dispatch(searchTotalPoints())
    dispatch(getMemberInfo())
    dispatch(getCartCount())
  }

  /* login (sit、stage、prod) */
  const accountLogin = useCallback(async () => {
    localStorage.clearExpectAntiFraud()
    dispatch(oAuthSignIn()).then((res) => {
      if (res.payload?.status === 400) {
        if (SHOW_CONSOLE) console.log('登入失敗')
        const error = res.payload.originError
        dispatch({
          type: 'error/setErrorState',
          payload: error, 
        })
      } else {
        if (SHOW_CONSOLE) console.log('登入成功')
        localStorage.set(LOCAL_STORAGE.CODE_VERIFIER, res.payload.codeVerifier)
        router.push(res.payload.authRedirectUri)
      }
    }) 
    /* ignore dependency: dispatch */
  }, [router.asPath])

  /* login (local) */
  const accountLocalLogin = useCallback(async () => {
    dispatch(ecLocalSignIn()).then((res) => {
      if (res.type.endsWith('/fulfilled')) {
        localStorage.set(LOCAL_STORAGE.TOKEN, res.payload.accessToken)
        getUserData()
      }
    })

    /* ignore dependency: dispatch */
  }, [router.pathname])

  /* logout */
  const accountLogout = useCallback(async (isExchange = false) => {
    setIsLoggingOut(true)
    await dispatch(ecSignOut({ isExchange })).then(() => {
      isExchange === false && router.push('/')
    })

    /* ignore dependency: dispatch & router */
  }, [])

  /* reset action record setting */
  useEffect(() => {
    const handleRouteChange = () => {
      setIsTokenVerifying(false)
      setIsLoggingOut(false)
      setIsExchanging(false)
      dispatch(setCanGetInitialData(false))
    }

    router.events.on('routeChangeStart', handleRouteChange)
    return () => {
      router.events.off('routeChangeStart', handleRouteChange)
    }

    /* ignore dependency: dispatch */
  }, [router])

  /* exchangeIn (from hotaigo) */
  useEffect(() => {
    if (router.query?.exchange === 'true' && Boolean(router.query?.token) && Boolean(router.query?.appsrc)) {
      const ecExchangeIn = async (params) => {
        setIsExchanging(true)
        try {
          const result = await auth.ecExchangeIn(params)
          const { data } = result
          document.cookie = `${ENV_INFO.hotaiId}=${data.hotaiMemberIdHash}; expires=${cookieExpires()}; path=/; secure; httpOnly`
          localStorage.set(LOCAL_STORAGE.TOKEN, data.accessToken)
          dispatch(setAccessTokenState(data.accessToken))
        }
        catch (error) {
          router.push('/')
        }
      }

      const handleExchangeIn = async () => {
        const params = {
          token: decodeURIComponent(router.query?.token),
          fromPlatform: router.query?.appsrc,
        }
        const newQuery = Object.keys(router.query).reduce((result, queryKey) => {
          if (queryKey !== 'token' && queryKey !== 'appsrc' && queryKey !== 'exchange') {
            result[queryKey] = router.query[queryKey]
          }
          return result
        }, {})

        router.replace(
          { query: newQuery },
          undefined,
          { shallow: true },
        )
        
        await accountLogout(true) // true => don't reload page
        await ecExchangeIn(params)
      }

      handleExchangeIn()
    }

    /* ignore dependency: dispatch、router */
  }, [router.query])

  /* refresh token */
  useEffect(() => {
    const tokenRefresh = () => {
      const token = localStorage.get(LOCAL_STORAGE.TOKEN)

      if (!!accessToken || !!token) {
        localStorage.remove(LOCAL_STORAGE.TOKEN)
        dispatch(ecRefreshToken({ accessToken: accessToken || token })).then((res) => {
          if (res.type.endsWith('/fulfilled')) {
            getUserData()
          }
        })
      }
    }

    if (hasRefreshToken) {
      dispatch(setCanGetInitialData(false))
      tokenRefresh()
    }

    /* ignore dependency: dispatch */
  }, [accessToken, hasRefreshToken])

  /* reLogin */
  useEffect(() => {
    if (hasRedirectOAuthLogin) {
      const isAuthPage = AUTH_PAGE.some(authPage => router.pathname.includes(authPage))
      const isAddCartAction = Boolean(sessionStorage.getParse(SESSION_STORAGE.STASH_CART)) // ADJUST: 不太好的判斷方式

      // NOTE: 如果不是必驗證身分的頁面 || 加入購物車功能觸發，背後清除所有登入相關資料 (不跳任何提醒)
      if (isAuthPage || isAddCartAction) {
        IS_DEVELOPMENT_ENV ? accountLocalLogin() : accountLogin()
      } else {
        localStorage.clearExpectAntiFraud()
        setIsTokenVerifying(false)
        setIsLoggingOut(false)
        setIsExchanging(false)
        dispatch(resetAuthSetting())
      }
    }
  }, [hasRedirectOAuthLogin])

  // verify token
  useEffect(() => {
    const token = localStorage.get(LOCAL_STORAGE.TOKEN)
    const isAuthPage = AUTH_PAGE.some(authPage => router.pathname.includes(authPage))

    // if exchangeIn, stop token verify and logout first
    if (router.query?.exchange === 'true' && Boolean(router.query?.token) && Boolean(router.query?.appsrc)) return

    // canGetInitialData 為 true 代表 token 驗證流程已結束
    if (canGetInitialData) setIsTokenVerifying(false) 
    // token 驗證流程結束 || 正在驗證中，則不再重複進行 token 存在判斷
    if (canGetInitialData || isTokenVerifying) return

    if (Boolean(token)) {
      const tokenVerify = (token) => {
        dispatch(ecVerifyToken(token))
          .then((res) => {
            if (res.type.endsWith('/fulfilled')) {
              getUserData()
            } else if (res?.payload?.status === 500) {
              localStorage.clearExpectAntiFraud()
            }
          })
      }

      setIsTokenVerifying(true)
      setIsExchanging(false)
      tokenVerify(token)
    } else if (Boolean(accessToken)) { // token hasn't value & accessToken has value => first login
      localStorage.set(LOCAL_STORAGE.TOKEN, accessToken)
      dispatch(setCanGetInitialData(true))
    } else if (isAuthPage) {
      if (isLoggingOut === false) {
        dispatch({
          type: 'error/setHasRedirectOAuthLoginState',
          payload: true,
        })
      }
      setIsLoggingOut(false)
    }

    /* ignore dependency: dispatch */
  }, [
    router.pathname,
    accessToken,
    canGetInitialData,
  ])

  return {
    accountLogin,
    accountLocalLogin, // NOTE: for local login
    accountLogout,
    accessToken,
    userName,
    userHotaiPoint,
    userCartAmount,
    isTokenVerifying,
    isExchanging,
  }
}