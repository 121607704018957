import { memo } from 'react'
import PropTypes from 'prop-types'
import classReader from 'utils/classReader'

const Separator = (props) => {
  const {
    className,
    id,
    vertical,
    inset,
    color,
    dark,
  } = props

  const separatorSide = vertical ? 'vertical' : 'horizontal'
  return (
    <hr
      className={classReader(
        'separator',
        `separator--${separatorSide}`,
        { [`separator--${separatorSide}-inset`]: inset },
        { [`bg-${color}`]: Boolean(color) },
        { 'separator--dark': dark },
        className,
      )}
      id={id}
    />
  )
}

Separator.propTypes = {
  className: PropTypes.string,
  id: PropTypes.string,
  vertical: PropTypes.bool,
  inset: PropTypes.bool,
  color: PropTypes.string,
  dark: PropTypes.bool,
}

Separator.defaultProps = {
  vertical: false,
  inset: false,
  dark: false,
}

export default memo(Separator)
