import { createAsyncThunk, createSlice } from '@reduxjs/toolkit'
// import { HYDRATE } from 'next-redux-wrapper'
import { setLoadingTextState } from 'slices/loadingSlice'
import oAuth from 'apis/hotai/oAuth'

const oAuthInitial = { authRedirectUri: '' }

export const oAuthSignIn = createAsyncThunk('oAuth/oAuthSignIn',
  async (params, thunkAPI) => {
    thunkAPI.dispatch(setLoadingTextState('前往和泰集團會員中心頁面中...'))
    try {
      const result = await oAuth.oAuthSignIn()
      const { success, data } = result
      if (success) return data
    } catch (error) {
      throw thunkAPI.rejectWithValue(error)
    }
  })

// NOTE: 搭配 toolkit 才能直接指向 state 的欄位，否則一般 reducer 需要這樣寫 => return { ...state, authState: action.payload }
export const oAuthSlice = createSlice({
  name: 'oAuth',
  initialState: oAuthInitial,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase('oAuth/oAuthSignIn/fulfilled', (state, action) => {
        state.authRedirectUri = action.payload
      })
    // .addCase(HYDRATE, (state, action) => {
    //   return { ...state }
    // })
  },
})

const { reducer } = oAuthSlice

export const selectAuthRedirectUri = (state) => state.oAuth.authRedirectUri

export default reducer