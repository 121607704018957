/*
 * className : Style名稱
 * id : 區塊名稱，方便 GTM 紀錄數據
 * position : 主要面板定位 top(靠上), bottom(靠下, 預設), center(中間)
 * slideIn : Popup 由哪個方向進入可視區，top, bottom(預設) , left, right
 * prompt : 開關，true, false
 * fill : 滿版，true, false
 * rounded : 主要面板圓角，true, false
 * children : 內容區塊
 */
import { useEffect, memo } from 'react'
import PropTypes from 'prop-types'
import classReader from 'utils/classReader'
import {
  motion, useMotionValue, useDragControls,
} from 'framer-motion'

const MenuPopup = (props) => {
  const {
    className,
    id,
    title,
    position,
    slideIn,
    prompt,
    fill,
    rounded,
    children,
    onTogglePopup,
    onClosePopup,
  } = props

  const defaultMenuY = 75
  const defaultMenuPaddingBottom = 15
  const defaultCloseMenuDistance = 50
  const controlerHeight = 35
  const titleHeight = 37

  const y = useMotionValue(defaultMenuY)
  const controls = useDragControls()

  function startDrag(event) {
    controls.start(event)
  }

  function checkMenuMovement(shouldCloseMenuDistence){
    y.get() > defaultMenuY + shouldCloseMenuDistence ? onClosePopup() : y.jump(defaultMenuY)
  }

  useEffect(() => {
    const body = window.document.body
    body.classList.toggle(classReader('overflow-hidden'), prompt)
    if (prompt) y.jump(defaultMenuY)
  }, [prompt])

  return (
    <section
      id={id}
      className={classReader(
        'mobile-menu-popup d-lg-none ',
        `mobile-menu-popup--position-${position}`,
        `mobile-menu-popup--slideIn-${slideIn}`,
        className,
      )}
      data-active={prompt}
    >
      <div className={classReader('mobile-menu-popup__bg')} onClick={onTogglePopup} />
      <motion.div className={
        classReader(
          'mobile-menu-popup__body',
          { 'mobile-menu-popup__body--fill': fill === true },
          { 'mobile-menu-popup__body--rounded': rounded === true },
          `mobile-menu-popup__body--slideIn-${slideIn}`,
        )}
      drag="y"
      style={{
        y,
        transition: 'none',
      }}
      dragMomentum={false} dragConstraints={{ top: 0 }}
      onDragEnd={() => checkMenuMovement(defaultCloseMenuDistance)}
      >
        <motion.div className={classReader('mobile-menu-popup__control')} dragControls={controls} onPointerDown={startDrag} 
          onDragEnd={() => checkMenuMovement(defaultCloseMenuDistance)} onClick={onClosePopup} />
        {Boolean(title) && (
          <div className={classReader('mobile-menu-popup__title')}>
            {title}
          </div>
        )}

        <div className={classReader('mobile-menu-popup__main scrollbar-y pl-1')} style={{
          overscrollBehavior: 'none',
          height: `calc(50% - ${defaultMenuY}px - ${controlerHeight}px - ${defaultMenuPaddingBottom}px - ${Boolean(title) ? titleHeight + 'px' : '0px' })`, 
        }}>
          {children}
        </div>
      </motion.div>
    </section>
  )
}

MenuPopup.propTypes = {
  className: PropTypes.string,
  id: PropTypes.string,
  title: PropTypes.node,
  position: PropTypes.string,
  slideIn: PropTypes.string,
  prompt: PropTypes.bool,
  fill: PropTypes.bool,
  rounded: PropTypes.bool,
  children: PropTypes.node,
  onTogglePopup: PropTypes.func,
  onClosePopup: PropTypes.func,
}

MenuPopup.defaultProps = {
  position: 'bottom',
  slideIn: 'bottom',
  prompt: false,
  onTogglePopup: () => { },
  onClosePopup: () => { },
}

export default memo( MenuPopup )