import { createAxios } from 'apis/axios'
import {
  ENV_INFO,
  CRYPTO_TYPE,
  OAUTH_GRANT_TYPE,
} from 'config/config'

// NOTE: 除了一開始的登入流程外，其他皆由 ec api 作為中繼站來呼叫
const oAuth = {
  /**
   * @function oAuthSignIn 和泰集團會員 - OAuth 登入
   * @param { string } codeVerifier
   * 
   * // response 200
   * @returns {
   *   {
   *      rtnCode: number,    // 成功: 0 | 邏輯錯誤: 99
   *      success: boolean,   // !! 文件上有該欄位，但實際沒有 !!
   *      msg: string,        // 成功 / 錯誤訊息
   *      data: {
   *        authRedirectUri: string
   *      } || null
   *   }
   * }
   * memo: authRedirectUri 包含下列參數 => 
   *         clientId - 用戶端 ID
   *         state - 隨機字串 (防 CSRF)(urlEncode)
   *         appId - 子系統名稱
   *         codeChallenge - PKCE 參數 ,Client 端 codeVerifier 加密後的字串 (urlEncode)
   *         codeChallengeMethod - PKCE 參數 , 加密方式 (S256)
   *         redirectUri - 應用程式 callback Url
   * 
   * // response 400 - 模型驗證
   * @returns {
   *   {
   *      title: 'One or more validation errors occurred.',
   *      status: 400,
   *      errors: {
   *        ClientId: [ 'The ClientId field is required.' ],
   *        CodeChallenge: [ 'The CodeChallenge field is required.' ]
   *      }
   *   }
   * }
   * 
   * // response 400 - 邏輯錯誤
   * @returns {
   *   {
   *      title: 'Occurred an OauthException',
   *      status: 400,
   *      detail: string,
   *      traceId: string,
   *      errors: {
   *        [string]: string
   *      }
   *   }
   * }
   * 
   * // response 500
   * @returns {
   *   {
   *      title: 'Occurred an OauthException',
   *      status: 500,
   *      detail: ''
   *   }
   * }
   */
  oAuthSignIn: async () => {
    const result = await createAxios().post(
      'Auth/oauth/redirectUri',
      null,
      { baseURL: ENV_INFO.apiRoot },
    )
    return result.data
  },  
}

export default oAuth
