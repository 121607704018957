import {
  ENV_INFO,
  APP_ID,
} from 'config/config'

const exchange = {
  /**
   * @function exchangeOutHTPOS 導向外部頁面  - 導連商城登入頁
   * @param { string } token - 原始未加密的外部 token
   */
  exchangeOutHTPOS: (token) => {
    const apiParams = {
      appsrc: APP_ID,
      token, 
    }
    const exchangeForm = document.createElement('form')
    exchangeForm.method = 'post'
    exchangeForm.action = ENV_INFO.HTExchangeUrl
    document.body.appendChild(exchangeForm)

    for (const apiKey in apiParams) {
      const formField = document.createElement('input')
      formField.type = 'hidden'
      formField.name = apiKey
      formField.value = apiParams[apiKey]

      exchangeForm.appendChild(formField)
    }
    exchangeForm.submit()
  },
}

export default exchange
