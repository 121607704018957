// toolkit official documents => https://redux-toolkit.js.org/introduction/getting-started
import { configureStore } from '@reduxjs/toolkit'
import { createWrapper } from 'next-redux-wrapper'

import rootReducer from './rootReducer'
import { IS_DEVELOPMENT_ENV } from 'config/config'

export const makeConfiguredStore = () => (
  configureStore({
    reducer: rootReducer,
    devTools: IS_DEVELOPMENT_ENV,
  })
)

export const wrapper = createWrapper(makeConfiguredStore)