import {
  useState,
  useRef,
  useEffect,
  memo,
} from 'react'
import Link from 'next/link'
import PropTypes from 'prop-types'
import classReader from 'utils/classReader'
import ReactLoading from 'react-loading'

import { COUNTRY_MAP, CITY_MAP } from 'config/options'
import { COLOR_MAPPING } from 'config/style'
import { PLACEHOLDER } from 'config/header'

import Input from 'common/form/Input'
import Button from 'common/Button'
// import Badge from 'common/Badge'
import Separator from 'common/Separator'
import MenuPopup from 'common/layout/mobile/MenuPopup'
import Img from 'common/Img'


const SearchBar = (props) => {
  const {
    keyword,
    prompt,
    // hotAddress,
    // searchHistory,
    searchResult,
    isSearching,
    onToggleSearch,
    onClosePopup,
    onChangeKeyword,
    onSubmitSearch,
  } = props

  const [isFocus, setIsFocus] = useState(false)
  const [focusedMenuItem, setFocusedMenuItem] = useState('')

  const InputBlockRef = useRef(null)

  const handleInputColor = () => {
    setIsFocus(prev => !prev)
  }

  const handleKeyDown = (key) => {
    if (key === 'Enter' && keyword.trim()) onSubmitSearch()
  }

  useEffect(() => {
    if (prompt) {
      const inputElement = InputBlockRef.current.querySelector('input')
      setTimeout(() => inputElement.focus(), 300)
    }
  }, [prompt])

  return (
    <>
      <div className={classReader('header__content d-lg-none')}>
        <div className={classReader('header__mobile-searchbar')}>
          <Button
            className={classReader('header__mobile-searchbar__btn', { pure: 'gtm-header-search-bar' })}
            label={keyword || PLACEHOLDER}
            icon="stroke-search"
            color="gray-1"
            textColor="gray-4"
            iconColor="primary"
            onClick={onToggleSearch}
            unelevated
            rounded
          />
        </div>
      </div>

      <MenuPopup
        id="header-searchbar"
        className={classReader('mobile-menu-popup--height200')}
        prompt={prompt}
        onTogglePopup={onToggleSearch}
        onClosePopup={onClosePopup}
        rounded
      >
        <div className={classReader('mobile-menu')}>
          <div ref={InputBlockRef} className={classReader('px-3')}>
            <Input
              id="search-bar-input"
              className={classReader('header__mobile-searchbar m-0', { pure: 'gtm-header-search-bar' })}
              label="Input"
              name="input"
              placeholder={PLACEHOLDER}
              type="text"
              value={keyword}
              color="primary"
              innerColor={isFocus ? 'white' : 'gray-1'}
              prependChild={<i className={classReader('icon icon-stroke-search icon-primary cursor-pointer', { pure: 'gtm-header-search-bar-search' })} onClick={onSubmitSearch} />}
              appendChild={Boolean(keyword) && (
                <i className={classReader('icon icon-stroke-close icon-gray-4 cursor-pointer')} onClick={() => onChangeKeyword('')} />
              )}
              onChange={onChangeKeyword}
              onKeyDown={handleKeyDown}
              onFocus={handleInputColor}
              onBlur={handleInputColor}
              dense
              rounded
            />

            <Separator color="white" className={classReader('my-3')} />

            <div className={classReader('virtual-scroll__content')} tabIndex="-1">
              {searchResult?.map(item => {
                const productCountry = COUNTRY_MAP.find(country => country.value === item.country)?.label || ''
                const productCity = item.city?.map(cityId => CITY_MAP.find(city => city.value === cityId)?.label || '').join('、')

                return (
                  <Link
                    prefetch={false}
                    key={item.productNumber}
                    aria-label="關鍵字搜尋"
                    className={classReader(
                      'item item-type item--clickable row no-wrap cursor-pointer manual-focusable pl-0 pr-0',
                      { 'manual-focusable--focused': focusedMenuItem === item.productNumber },
                      { pure: 'gtm-header-search-bar-item' },
                    )}
                    href={`/product/${item.productNumber}`}
                    tabIndex="-1"
                    onClick={onToggleSearch}
                    onMouseEnter={() => setFocusedMenuItem(item.productNumber)}
                    passHref
                  >
                    <div className={classReader('focus-helper')} tabIndex="-1" />

                    <div className={classReader('item__section item__section--side item__section--avatar item--column')}>
                      <Img
                        className={classReader('rounded-ec')}
                        src={item.mediaPath[0]}
                        alt="search-img"
                        height={50}
                        width={50}
                      />
                    </div>

                    <div className={classReader('item__section item__section--main item--column')}>
                      <div className={classReader('item__label ellipsis')}>{item.title}</div>

                      <div className={classReader('item__label item__label--caption')}>{`${productCountry} | ${productCity}`}</div>
                    </div>
                  </Link>
                )
              })}

              {isSearching && (
                <ReactLoading
                  className={classReader('m-auto')}
                  type="spin"
                  color={COLOR_MAPPING.primary}
                  height={50}
                  width={50}
                />
              )}
            </div>
          </div>


          {/* <Separator color="gray-2" className={classReader('my-4')} />

          <div className={classReader('mobile-menu__content')}>
            <h3 className={classReader('text-left')}>搜尋紀錄</h3>
            <div className={classReader('d-flex')}>
              {searchHistory.map((item, index) => (
                <span key={index}>
                  <Badge
                    color="gray-1"
                    className={classReader('mr-2')}
                    textColor="gray-9"
                    rounded
                  >
                    {item.name}
                    <i className={classReader('icon icon-stroke-close icon-sm')} />
                  </Badge>
                </span>
              ))}
            </div>
          </div>

          <Separator color="gray-2" className={classReader('my-4')} /> */}

          {/* <div className={classReader('mobile-menu__content')}>
            <h3 className={classReader('text-left')}>熱門城市或關鍵字</h3>
            <div className={classReader('d-flex flex-direction-row wrap')}>
              {hotAddress.map((item, index) => (
                <Link key={index}
                  href={`${item.link}`}
                  className={classReader('p-2 border rounded m-1')}
                >
                  {item.name}
                </Link>
              ))}
            </div>
          </div>

          <Separator color="gray-2" className={classReader('my-4')} /> */}

          {/* <div className={classReader('mobile-menu__content')}>
            <h3 className={classReader('text-left')}>最近預覽</h3>
          </div> */}
        </div>
      </MenuPopup>
    </>
  )
}

SearchBar.propTypes = {
  keyword: PropTypes.string,
  prompt: PropTypes.bool,
  // hotAddress: PropTypes.array,
  // searchHistory: PropTypes.array,
  searchResult: PropTypes.array,
  isSearching: PropTypes.bool,
  onToggleSearch: PropTypes.func,
  onClosePopup: PropTypes.func,
  onChangeKeyword: PropTypes.func,
  onSubmitSearch: PropTypes.func,
}

SearchBar.defaultProps = {
  keyword: '',
  prompt: false,
  // hotAddress: [],
  // searchHistory: [],
  searchResult: [],
  isSearching: false,
  onToggleSearch: () => { },
  onClosePopup: () => { },
  onChangeKeyword: () => { },
  onSubmitSearch: () => { },
}

export default memo(SearchBar)