import {
  memo, useState, useEffect, 
} from 'react'

import classReader from 'utils/classReader'
import dayjs from 'dayjs'
import PropTypes from 'prop-types'
import { WEEKDAY_LOCALES } from 'config/style'

const PinnedAnnouncement = (props) => {
  const {
    className,
    startDate,
    endDate,
    blockTimeAdjust,
    text,
    type,
    textColor,
    bgColor, 
    marquee,
  } = props

  const [announcementText, setAnnouncementText] = useState('')
  const startDateAdj = blockTimeAdjust?.start ? dayjs(startDate).add(0 - blockTimeAdjust.start, 'minutes') : dayjs(startDate)
  const endDateAdj = blockTimeAdjust?.end ? dayjs(endDate).add(blockTimeAdjust.end, 'minutes') : dayjs(endDate)
  const isStartEndAtSameDate = startDateAdj.format('YYYY-MM-DD') === endDateAdj.format('YYYY-MM-DD')
  
  useEffect(() => {
    switch (type) {
    // 維護公告
    case 'MAINT':
      const maintStr = `為提供更好的服務品質，網站將於
        ${dayjs(startDateAdj).year()}年
        ${dayjs(startDateAdj).month() + 1}月
        ${dayjs(startDateAdj).date()}日(星期${WEEKDAY_LOCALES[dayjs(startDateAdj).day()]})
        ${startDateAdj.format('HH:mm')} 至
        ${!isStartEndAtSameDate && dayjs(endDateAdj).year() + '年' || ''}
        ${!isStartEndAtSameDate && dayjs(endDateAdj).month() + 1 + '月' || ''}
        ${!isStartEndAtSameDate && dayjs(endDateAdj).date() + '日(星期' + WEEKDAY_LOCALES[dayjs(endDateAdj).day()] + ')' || ''}
        ${endDateAdj.format('HH:mm')}
        ，進行系統主機維護作業，造成不便，尚祈見諒。`
      setAnnouncementText(maintStr)
      break
    // 置頂公告
    case 'DEFAULT':
      setAnnouncementText(text)
    }
  }, [type])

  useEffect(() => {
    
  }, [announcementText])

  return (
    <section className={classReader(`bg-${bgColor} pt-2 pl-2 pr-2`, className)}>
      {marquee ? ( 
        <marquee>{announcementText}</marquee>
      ) : (
        <div className={classReader(`text-center text-${textColor}`)}>{announcementText}</div>
      )}
    </section>
  )
}

PinnedAnnouncement.propTypes = {
  className: PropTypes.string,
  endDate: PropTypes.string,
  startDate: PropTypes.string, 
  blockTimeAdjust: PropTypes.object,
  text: PropTypes.string,
  type: PropTypes.string,
  textColor: PropTypes.string,
  bgColor: PropTypes.string,
  marquee: PropTypes.bool, 
}

PinnedAnnouncement.defaultProps = {
  className: '',
  endDate: '',
  startDate: '', 
  blockTimeAdjust: {},
  text: '',
  type: 'DEFAULT',
  textColor: 'white',
  bgColor: 'primary',
  marquee: false, 
}

export default memo(PinnedAnnouncement)