import { createSlice } from '@reduxjs/toolkit'

const loadingInitial = {
  isLoading: false,
  loadingText: '',
}

export const loadingSlice = createSlice({
  name: 'loading',
  initialState: loadingInitial,
  reducers: {
    setLoadingTextState(state, action) {
      state.loadingText = action.payload
    },
    openLoading(state) {
      if (state.isLoading === false) {
        state.isLoading = true
      }
    },
    closeLoading(state) {
      state.isLoading = false
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase('auth/resetAuthSetting', () => loadingInitial)
      .addMatcher((action) => {
        return action.type.endsWith('/pending') &&
            (
              action.type.startsWith('localAuth/ecLocalSignIn') ||
              action.type.startsWith('oAuth/oAuthSignIn') ||
              // action.type.startsWith('auth/ecVerifyToken') ||
              action.type.startsWith('auth/ecSignOut') ||
              action.type.startsWith('cart/addCartProduct')
              // action.type.startsWith('point/searchTotalPoints')
            )
      },
      (state) => {
        state.isLoading = true
      })
      .addMatcher((action) => {
        return action.type.endsWith('/fulfilled') &&
            (
              action.type.startsWith('localAuth/ecLocalSignIn') ||
              action.type.startsWith('auth/ecSignIn') ||
              action.type.startsWith('auth/ecSignOut') ||
              action.type.startsWith('member/getMemberInfo') ||
              action.type.startsWith('cart/addCartProduct')
            )
      },
      () => loadingInitial)
      .addMatcher((action) => {
        return action.type.endsWith('/rejected') &&
            (
              action.type.startsWith('auth/ecVerifyToken') ||
              action.type.startsWith('cart/addCartProduct')
            )
      },
      (state, action) => {
        if (action.payload === undefined || action.payload.status === 500 || action.payload.status === 400) {
          state.isLoading = false
        }
      })
      .addMatcher((action) => {
        return action.type.endsWith('/rejected') &&
            (
              action.type.startsWith('localAuth/ecLocalSignIn') ||
              action.type.startsWith('oAuth/oAuthSignIn') ||
              action.type.startsWith('auth/ecSignIn') ||
              action.type.startsWith('auth/ecSignOut')
              // action.type.startsWith('member/getMemberInfo') //NOTE: 待確認
            )
      },
      () => loadingInitial)
  },
})

const { actions, reducer } = loadingSlice

export const {
  setLoadingTextState,
  openLoading,
  closeLoading,
} = actions
export const selectIsLoading = (state) => state.loading.isLoading
export const selectLoadingText = (state) => state.loading.loadingText

export default reducer