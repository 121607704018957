import { ENV_INFO } from 'config/config'
import { WEB_TITLE } from 'config/head'

export const TRAVEL_TYPE_OPTIONS = [
  {
    label: '餐廳美食',
    value: 1,
  },
  {
    label: '景點門票',
    value: 2,
  },
  {
    label: '溫泉SPA',
    value: 3,
  },
]

export const NOTICE_DATA = [
  '勿隨意點擊不明網址',
  '勿隨意點擊不明附件',
  '勿隨意提供個人資料',
  '勿操作ATM進行交易',
]

export const VERIFIED_DATA = [
  {
    text: WEB_TITLE,
    url: ENV_INFO.root,
  },
]

const cityCode = {
  tokyo: {
    type: [],
    cityCode: [
      '00101',
      '00102',
      '00103',
      '00104',
      '00105',
      '00106',
      '00107',
      '00108',
      '00109',
      '00110',
      '00111',
      '00112',
      '00113',
      '00114',
      '00115',
      '00116',
      '00117',
      '00118',
      '00119',
      '00120',
      '00121',
      '00122',
      '00123',
      '00124',
      '00125',
      '00126',
      '00127',
      '00128',
      '00129',
      '00130',
      '00131',
      '00132',
      '00133',
      '00134',
      '00136',
      '00137',
      '00139',
      '00140',
      '00141',
      '00142',
      '00143',
      '00144',
      '00145',
      '00146',
      '00147',
      '00148',
    ],
  },
  osaka: {
    type: [],
    cityCode: [
      '00301',
      '00302',
      '00303',
      '00304',
      '00305',
      '00306',
      '00307',
      '00308',
      '00309',
      '00310',
      '00311',
      '00312',
      '00313',
      '00314',
      '00315',
      '00316',
      '00317',
      '00318',
      '00319',
      '00320',
      '00321',
      '00322',
      '00323',
      '00324',
      '00325',
      '00326',
      '00327',
      '00328',
      '00329',
    ],
  },
  hotel: {
    type: [
      '0301',
      '0305',
      '0306',
      '0401',
      '0402',
      '0403',
      '0404',
      '0405',
      '0504',
      '1100',
      '1200',
    ],
    cityCode: [
      '00001',
      '00002',
      '00003',
      '00004',
      '00005',
      '00006',
      '00007',
      '00008',
      '00009',
      '00010',
      '00011',
      '00013',
      '00014',
      '00015',
      '00016',
      '00017',
      '00018',
      '00019',
      '00020',
      '00099',
    ],
  },
  tainan: {
    type: [],
    cityCode: ['00012'],
  },
}

export const RECOMMEND_KEYWORDS = [
  {
    id: 'tokyo',
    flag: 'japan.svg',
    isLocal: false,
    title: '日本',
    desc: '',
    type: [],
    url: `/search?${new URLSearchParams({
      location: cityCode.tokyo.cityCode.join(','),
      page: 1,
      sortType: 'relevant',
    }).toString()}`,
    cityCode: cityCode.tokyo.cityCode,
  },
  {
    id: 'osaka',
    flag: 'th.svg',
    isLocal: false,
    title: '泰國',
    desc: '',
    type: [],
    url: `/search?${new URLSearchParams({
      location: cityCode.osaka.cityCode.join(','),
      page: 1,
      sortType: 'relevant',
    }).toString()}`,
    cityCode: cityCode.osaka.cityCode,
  },
  {
    id: 'hotel',
    flag: 'taiwan.svg',
    isLocal: true,
    title: '台灣旅遊',
    desc: '',
    type: cityCode.hotel.type,
    url: `/search?${new URLSearchParams({
      location: cityCode.hotel.cityCode.join(','),
      type: cityCode.hotel.type.join(','),
      page: 1,
      sortType: 'relevant',
    }).toString()}`,
    cityCode: cityCode.hotel.cityCode,
  },
  {
    id: 'tainan',
    flag: 'pineapple.svg',
    isLocal: true,
    title: '台南特輯',
    desc: '台南',
    type: [],
    url: `/search?${new URLSearchParams({
      location: cityCode.tainan.cityCode.join(','),
      keyword: '台南',
      page: 1,
      sortType: 'relevant',
    }).toString()}`,
    cityCode: cityCode.tainan.cityCode,
  },
]