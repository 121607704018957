/* NOTE: for local login */

import { createAsyncThunk, createSlice } from '@reduxjs/toolkit'
import { localStorage } from 'utils/storage'
import { setLoadingTextState } from 'slices/loadingSlice'
import auth from 'apis/auth/auth'

const localAuthInitial = {}

export const ecLocalSignIn = createAsyncThunk('localAuth/ecLocalSignIn',
  async (params, thunkAPI) => {
    localStorage.clearExpectAntiFraud()
    thunkAPI.dispatch(setLoadingTextState('會員登入中...'))
    try {
      const result = await auth.ecTestSignIn()
      const { data } = result
      const loginData = {
        accessToken: data.ecToken.accessToken,
        name: data.ecToken.name,
        hotaiPoint: data.ecToken.hotaiPoint,
      }

      return loginData
    } catch (error) {
      throw thunkAPI.rejectWithValue(error)
    }
  })

export const localAuthSlice = createSlice({
  name: 'localAuth',
  initialState: localAuthInitial,
  reducers: {},
})

const { reducer } = localAuthSlice
export default reducer