import {
  createAction,
  createAsyncThunk,
  createSlice,
} from '@reduxjs/toolkit'
// import { HYDRATE } from 'next-redux-wrapper'
import { LOCAL_STORAGE, ENV_INFO } from 'config/config'
import { localStorage } from 'utils/storage'
import { setLoadingTextState } from 'slices/loadingSlice'
import auth from 'apis/auth/auth'
import { cookieExpires } from 'utils/date'
const authInitial = {
  accessToken: '',
  canGetInitialData: false,
  beforeLoginPath: '',
}

export const ecSignIn = createAsyncThunk('auth/ecSignIn',
  async (params, thunkAPI) => {
    try {
      const result = await auth.ecSignIn(params)
      const { data } = result
      localStorage.set(LOCAL_STORAGE.TOKEN, data.accessToken)
      document.cookie = `${ENV_INFO.hotaiId}=${data.hotaiMemberIdHash}; expires=${cookieExpires()}; path=/; secure; httpOnly`
      return data
    } catch (error) {
      throw thunkAPI.rejectWithValue(error)
    }
  })

export const ecSignOut = createAsyncThunk('auth/ecSignOut',
  async (params, thunkAPI) => {
    if (params.isExchange === false) {
      thunkAPI.dispatch(setLoadingTextState('登出中...'))
    }

    try {
      const result = await auth.ecSignOut()
      document.cookie = `${ENV_INFO.hotaiId}=; expires=${cookieExpires(-1)}; path=/; secure; httpOnly`
      return true
    } catch (error) {
      throw thunkAPI.rejectWithValue(error)
    } finally {
      localStorage.clearExpectAntiFraud()
    }
  })

export const ecVerifyToken = createAsyncThunk('auth/ecVerifyToken',
  async (params, thunkAPI) => {
    // if (isLoadingPage) thunkAPI.dispatch({ type: 'loading/openLoading', payload: true })
    try {
      const result = await auth.ecVerifyToken()
      return { accessToken: params }
    } catch (error) {
      throw thunkAPI.rejectWithValue(error)
    }
  })

export const ecRefreshToken = createAsyncThunk('auth/ecRefreshToken',
  async (params, thunkAPI) => {
    try {
      const result = await auth.ecRefreshToken(params)
      const { data } = result

      localStorage.set(LOCAL_STORAGE.TOKEN, data.accessToken)
      return data
    } catch (error) {
      throw thunkAPI.rejectWithValue(error)
    }
  })

export const resetAuthSetting = createAction('auth/resetAuthSetting')

export const authSlice = createSlice({
  name: 'auth',
  initialState: authInitial,
  reducers: {
    setAccessTokenState(state, action) {
      state.accessToken = action.payload
    },
    setCanGetInitialData(state, action) {
      state.canGetInitialData = action.payload
    },
    setBeforeLoginPath(state, action) {
      state.beforeLoginPath = action.payload
    },
  },
  extraReducers: (builder) => {
    builder
      // .addCase(HYDRATE, (state, action) => {
      //   state.canGetInitialData = false
      // })
      .addMatcher((action) => {
        return action.type.endsWith('/fulfilled') &&
            (
              action.type.startsWith('localAuth/ecLocalSignIn') ||
              action.type.startsWith('auth/ecSignIn') ||
              action.type.startsWith('auth/ecVerifyToken') ||
              action.type.startsWith('auth/ecRefreshToken')
            )
      },
      (state, action) => {
        state.accessToken = action.payload.accessToken
        state.canGetInitialData = true
      })
      .addMatcher((action) => (
        action.type === 'auth/resetAuthSetting' ||
        action.type === 'auth/ecSignOut/fulfilled' ||
          action.type === 'auth/ecSignOut/rejected' ||
          action.type === 'auth/ecRefreshToken/rejected'
      ),
      () => authInitial)
  },
})

const { actions, reducer } = authSlice

export const {
  setAccessTokenState,
  setCanGetInitialData,
  setBeforeLoginPath,
} = actions

export const selectAccessToken = (state) => state.auth.accessToken
export const selectCanGetInitialData = (state) => state.auth.canGetInitialData
export const selectBeforeLoginPath = (state) => state.auth.beforeLoginPath

export default reducer