import { useState, useEffect } from 'react'
import { useRouter } from 'next/router'
import Link from 'next/link'
import $xor from 'lodash/xor'

import { THEME_OPTIONS } from 'config/options'
import classReader from 'utils/classReader'

import Slider from 'common/Slider'

const TopRecommendLikes = () => {
  const router = useRouter()

  const [activeRecommend, setActiveRecommend] = useState(0)

  useEffect(() => {
    const { type } = router.query || {}

    if (Boolean(type)) {
      const typeList = type.split(',') || []
      const checkTheme = THEME_OPTIONS.find(themeOption => $xor(themeOption.tags, typeList).length === 0)

      setActiveRecommend(checkTheme?.value || 0)
    } else {
      setActiveRecommend(0)
    }
  }, [router.query])

  return (
    <section id="TopRecommendLikes" className={classReader('mt-3 mb-3 d-none d-lg-block', { TopRecommendLikesStyled: 'main' })}>
      <div className={classReader('container p-0', { TopRecommendLikesStyled: 'container' })}>
        <Slider
          className={classReader({ TopRecommendLikesStyled: 'slider' })}
          spaceBetween={8}
          slidesPerView="auto"
          slidesPerGroup={2}
          slides={THEME_OPTIONS.map((theme, index) => ({
            id: `slider${index}`,
            content: (
              <Link
                href={theme.link}
                aria-label={theme.label}
                className={classReader(
                  { TopRecommendLikesStyled: 'link' },
                  { 'text-primary': activeRecommend === theme.value },
                  { pure: 'gtm-top-recommend-likes-link' },
                )}
                prefetch={false}
              >
                {theme.label}
              </Link>
            ),
          }))}
          customLeftNavigation={(prevFunc) => (
            <i className={classReader('icon icon-stroke-arrow-left icon-md icon-gray-3 cursor-pointer')} onClick={prevFunc} />
          )}
          customRightNavigation={(nextFunc) => (
            <i className={classReader('icon icon-stroke-arrow-right icon-md icon-gray-3 cursor-pointer')} onClick={nextFunc} />
          )}
          autoWidth
          clickable
        />
      </div>
    </section>
  )
}

export default TopRecommendLikes