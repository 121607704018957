export const FOOTER_NAV = [
  {
    title: '關於和泰相關',
    className: 'col',
    option: [
      {
        id: 1,
        name: '和泰Points介紹',
        link: 'https://www.hotaimember.com.tw/hotaipoints',
        target: '_blank',
      },
      {
        id: 2,
        name: '和泰集團會員',
        link: 'https://www.hotaimember.com.tw/member',
        target: '_blank',
      },
      {
        id: 3,
        name: '和泰集團會員隱私權政策',
        link: 'https://www.hotaimember.com.tw/agreement/privacy_policy',
        target: '_blank',
      },
      {
        id: 4,
        name: '和泰集團常見問題',
        link: 'https://www.hotaimember.com.tw/FAQ/list',
        target: '_blank',
      },
      {
        id: 5,
        name: '和泰集團會員條款',
        link: 'https://www.hotaimember.com.tw/agreement/hotaimember_service',
        target: '_blank',
      },
    ],
  },
  {
    title: '關於去趣',
    className: 'col',
    option: [
      {
        id: 1,
        name: 'FAQ專區',
        link: '/faq',
        target: '_self',
      },
      {
        id: 2,
        name: '客服專區',
        link: '/custom-service',
        target: '_self',
      },
      {
        id: 3,
        name: '隱私權政策',
        link: '/privacy-policy',
        target: '_self',
      },
      {
        id: 4,
        name: '免責聲明',
        link: '/disclaimer',
        target: '_self',
      },
      {
        id: 5,
        name: '防詐騙提醒',
        link: '/anti-fraud-reminder',
        target: '_self',
      },   
      {
        id: 6,
        name: '和泰集團相關服務',
        link: '/group',
        target: '_self',
      },
    ],
  },
]

export const FOOTER_COMPANY_INFO = {
  title: '和漾旅行社股份有限公司',
  email: 'service@chictrip.com.tw',
  option: [
    '旅遊商品由 和漾旅行社股份有限公司 提供',
    '代表人：吳品璁 |  統一編號：90356017 | 旅行社註冊編號：850800 | 品保協會會員編號：北2598號',
    '',
    '',
    '其他商品由 和泰聯網股份有限公司 提供',
    '統一編號：55384114  | 地址：104台北市中山區松江路433號4樓 | 聯絡信箱：gocs@hotaigo.com.tw',
  ],
  attention: '聯絡人：蘇聖惠',
  customer: {
    serviceHours: '週一至週五 (09:00-17:30)',
    tel: ['(02)2506-2121 分機1436'],
  },
  fax: [],
}