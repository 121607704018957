import { memo } from 'react'
import PropTypes from 'prop-types'
import classReader from 'utils/classReader'

import Dialog from 'common/Dialog'
import Card from 'common/card/Card'
import CardSection from 'common/card/CardSection'
import Button from 'common/Button'
import Separator from 'common/Separator'

const Modal = (props) => {
  const {
    className,
    id,
    prompt,
    title,
    titleAlign,
    separator,
    persistent,
    close,
    sectionChildren,
    children,
    onClose,
  } = props

  const hasTitleBlock = Boolean(title) || close
  return (
    <Dialog
      prompt={prompt}
      onClose={onClose}
      persistent={persistent}
    >
      <Card
        className={classReader(className)}
        id={id}
        title={title}
      >
        {hasTitleBlock && (
          <CardSection>
            <div className={classReader('d-flex justify-content-between align-items-center')}>
              <div className={classReader('card__title', { [`text-${titleAlign}`]: Boolean(titleAlign) })}>
                {title}
              </div>

              {close && (
                <Button
                  className={classReader('card__close-button')}
                  icon="stroke-close"
                  size="md"
                  color="gray-3"
                  textColor="gray-3"
                  onClick={() => onClose(false)}
                  flat
                  round
                />
              )}
            </div>
          </CardSection>
        )}

        {separator && (<Separator color="gray-2" />)}

        {sectionChildren !== void 0 && (
          <CardSection>
            {sectionChildren}
          </CardSection>
        )}
        {children}
      </Card>
    </Dialog>
  )
}

Modal.propTypes = {
  className: PropTypes.string,
  id: PropTypes.string,
  prompt: PropTypes.bool,
  title: PropTypes.string,
  titleAlign: PropTypes.string,
  separator: PropTypes.bool,
  persistent: PropTypes.bool,
  close: PropTypes.bool,
  sectionChildren: PropTypes.node,
  children: PropTypes.node,
  onClose: PropTypes.func,
}

Modal.defaultProps = {
  prompt: false,
  title: '',
  separator: false,
  persistent: false,
  close: false,
  onClose: () => { },
}

export default memo(Modal)
