/* 時間格式
 * 避免時間於資料間存取、傳遞時受
 *   1. Client 瀏覽器地理位置預設
 *   2. Server 地區時區預設
 *   3. Code 設定
 *   4. DB 時區預設/設定
 * 導致時間產生 +N 問題
 * 前端與後端間的溝通，請依據以下規範
 *   1. 統一使用 Taiwan Taipei 時區時間(+8)
 *   2. 傳遞一律使用字串，不含T
 */

export const DATE_FORMAT = 'YYYY-MM-DD'

export const TIME_FORMAT = 'HH:mm:ss'

export const DATETIME_FORMAT = 'YYYY-MM-DD HH:mm:ss'

// 網站全站維護時間
export const WEBSITE_MAINTENANCE_DATE = {
  isActive: process.env.NEXT_PUBLIC_WEB_MAINTENAN_ISACTIVE === 'true',
  start: process.env.NEXT_PUBLIC_WEB_MAINTENAN_START || '',
  end: process.env.NEXT_PUBLIC_WEB_MAINTENAN_END || '',
  showTimeAdjust: {
    start: process.env.NEXT_PUBLIC_WEB_MAINTENAN_SHOW_ADJ_START || 45, // 提前 N 分鐘顯示置頂維護公告訊息
    end: process.env.NEXT_PUBLIC_WEB_MAINTENAN_SHOW_ADJ_END || 0, // 延後 N 分鐘顯示置頂維護公告訊息
  },
  blockTimeAdjust: {
    start: process.env.NEXT_PUBLIC_WEB_MAINTENAN_BLOCK_ADJ_START || 15, // 提前 N 分鐘阻擋使用者瀏覽
    end: process.env.NEXT_PUBLIC_WEB_MAINTENAN_BLOCK_ADJ_END || 0, // 延後 N 分鐘開放使用者瀏覽
  },
  text: process.env.NEXT_PUBLIC_WEB_MAINTENAN_TEXT || '',
  type: process.env.NEXT_PUBLIC_WEB_MAINTENAN_TYPE || 'MAINT',
}
  
// 金流維護時間
export const PAYMENT_MAINTENANCE_TIME = {
  isActive: process.env.NEXT_PUBLIC_PAYMENT_MAINTENAN_ISACTIVE === 'true',
  start: process.env.NEXT_PUBLIC_PAYMENT_MAINTENAN_START || '',
  end: process.env.NEXT_PUBLIC_PAYMENT_MAINTENAN_END || '',
  blockTimeAdjust: {
    start: process.env.NEXT_PUBLIC_PAYMENT_MAINTENAN_BLOCK_ADJ_START || 15, // 提前 N 分鐘阻擋結帳並跳出公告
    end: process.env.NEXT_PUBLIC_PAYMENT_MAINTENAN_BLOCK_ADJ_END || 15, // 延後 N 分鐘開放結帳
  },
}