import {
  useState,
  useEffect,
  useLayoutEffect,
  useRef,
  memo,
} from 'react'
import Link from 'next/link'
import PropTypes from 'prop-types'
import classReader from 'utils/classReader'
import ReactLoading from 'react-loading'

import { PLACEHOLDER } from 'config/header'
import { COUNTRY_MAP, CITY_MAP } from 'config/options'
import { COLOR_MAPPING } from 'config/style'
import { useWindowSize } from 'hooks/useWindowSize'

import Input from 'common/form/Input'
import Img from 'common/Img'

const useIsomorphicLayoutEffect = typeof window !== 'undefined' ? useLayoutEffect : useEffect

const initialMenuStyle = {
  minWidth: '',
  top: '',
  left: '',
}

const SearchBar = (props) => {
  const {
    keyword,
    hotAddress,
    searchHistory,
    searchResult,
    isSearching,
    onChangeKeyword,
    onSubmitSearch,
  } = props

  const { windowWidthSize } = useWindowSize()

  const [isMenuOpen, setIsMenuOpen] = useState(false)
  const [menuStyle, setMenuStyle] = useState(initialMenuStyle)
  const [focusedMenuItem, setFocusedMenuItem] = useState('')

  const menuRef = useRef(null)
  const controlRef = useRef(null)

  const toggleMenu = (e) => {
    // 點擊到 input 或是 button (清除按鈕) 時， 開啟 / 不關閉 Menu
    const isActionTarget = e.target?.tagName === 'INPUT' || e.target?.tagName === 'BUTTON'
    setIsMenuOpen(prev => isActionTarget || !prev)
  }

  const handleKeyDown = (key) => {
    if (key === 'Enter' && keyword.trim()) {
      setIsMenuOpen(false)
      onSubmitSearch()
    }
  }

  // 依據畫面長寬變動，一同改變  Menu 位置
  useIsomorphicLayoutEffect(() => {
    if (isMenuOpen === true) {
      const controlTop = controlRef.current?.offsetTop || 0
      const controlLeft = controlRef.current?.offsetLeft || 0
      const controlHeight = controlRef.current?.offsetHeight || 0
      const controlWidth = controlRef.current?.offsetWidth || 0

      let menuTop = controlTop + controlHeight + 20 + 6

      setMenuStyle(prev => ({
        ...prev,
        minWidth: `${controlWidth}px`,
        top: `${menuTop}px`,
        left: `${controlLeft}px`,
      }))
    }
  }, [windowWidthSize, isMenuOpen])

  // Menu 開啟 => 顯示符合的項目 + 附值輸入框內容
  // Menu 關閉 => 重置輸入框內容
  // useEffect(() => {
  //   handleSearch(value)
  // }, [
  //   isMenuOpen,
  //   value,
  //   handleSearch
  // ])

  // 點擊元件外的範圍時，關閉 Menu
  useEffect(() => {
    const handleClickOutside = (event) => {
      if (menuRef.current === null || controlRef.current === null) return

      const isClickSelectControl = controlRef.current.contains(event?.target)
      const isClickMenu = menuRef.current.contains(event?.target)
      if (isClickMenu === false && isClickSelectControl === false) {
        setIsMenuOpen(false)
      }
    }

    document.addEventListener('mousedown', handleClickOutside)

    return () => {
      document.removeEventListener('mousedown', handleClickOutside)
    }
  }, [])

  return (
    <>
      <div ref={controlRef} onClick={toggleMenu}>
        <Input
          className={classReader('header__search-bar-input', { pure: 'gtm-header-search-bar' })}
          name="keyword"
          value={keyword}
          placeholder={PLACEHOLDER}
          prependChild={(
            <i className={classReader('icon icon-stroke-search icon-primary cursor-pointer', { pure: 'gtm-header-search-bar-search' })}
              onClick={onSubmitSearch} />)}
          appendChild={Boolean(keyword) && (
            <i className={classReader('icon icon-stroke-close icon-gray-4 cursor-pointer')} onClick={() => onChangeKeyword('')} />
          )}
          maxLength={80}
          onChange={onChangeKeyword}
          onKeyDown={handleKeyDown}
        />
      </div>
      {isMenuOpen === true && (
        <div
          ref={menuRef}
          className={classReader('select-menu select-menu--square scrollbar-y rounded-10 header__search-bar-menu')}
          tabIndex="-1"
          style={menuStyle}
        >
          <div className={classReader('virtual-scroll__content')} tabIndex="-1">
            {searchResult?.map(item => {
              const productCountry = COUNTRY_MAP.find(country => country.value === item.country)?.label || ''
              const productCity = item.city?.map(cityId => CITY_MAP.find(city => city.value === cityId)?.label || '').join('、')
              
              return (
                <Link
                  prefetch={false}
                  key={item.productNumber}
                  aria-label="搜尋"
                  className={classReader(
                    'item item-type item--clickable row no-wrap cursor-pointer manual-focusable',
                    { 'manual-focusable--focused': focusedMenuItem === item.productNumber },
                    { pure: 'gtm-header-search-bar-item' },
                  )}
                  href={`/product/${item.productNumber}`}
                  tabIndex="-1"
                  onClick={() => setIsMenuOpen(false)}
                  onMouseEnter={() => setFocusedMenuItem(item.productNumber)}
                  passHref
                >
                  <div className={classReader('focus-helper')} tabIndex="-1" />

                  <div className={classReader('item__section item__section--side item__section--avatar item--column')}>
                    <Img
                      className={classReader('rounded-ec')}
                      src={item.mediaPath[0]}
                      alt="search-img"
                      height={50}
                      width={50}
                    />
                  </div>

                  <div className={classReader('item__section item__section--main item--column')}>
                    <div className={classReader('item__label ellipsis')}>{item.title}</div>

                    <div className={classReader('item__label item__label--caption')}>{`${productCountry} | ${productCity}`}</div>
                  </div>
                </Link>
              )
            })}

            {isSearching && (
              <div className={classReader('item item-type row no-wrap')}>
                <div className={classReader('item__section item__section--main item--column')}>
                  <div className={classReader('item__label')}>
                    <ReactLoading
                      className={classReader('m-auto')}
                      type="spin"
                      color={COLOR_MAPPING.primary}
                      height={30}
                      width={30}
                    />
                  </div>
                </div>
              </div>
            )}
          </div>
        </div>
      )}
    </>
  )
}

SearchBar.propTypes = {
  keyword: PropTypes.string,
  hotAddress: PropTypes.array,
  searchHistory: PropTypes.array,
  searchResult: PropTypes.array,
  isSearching: PropTypes.bool,
  onChangeKeyword: PropTypes.func,
  onSubmitSearch: PropTypes.func,
}

SearchBar.defaultProps = {
  keyword: '',
  hotAddress: [],
  searchHistory: [],
  searchResult: [],
  isSearching: false,
  onChangeKeyword: () => { },
  onSubmitSearch: () => { },
}

export default memo(SearchBar)
