export const ERROR_TYPE_CODE = {
  'SystemError': 0,
  'DuplicateAccount': 1,
  'AccountOrPasswordIncorrect': 2,
  'NotFound': 3,
  'VerificationFailed': 4,
  'NotFoundMember': 5,
  'InvalidData': 6,
  'InvalidEmail': 7,
  'InvalidPhone': 8,
  'MemberIsDisable': 9,
  'Unauthorized': 10,
  'Forbidden': 11,
  'InvalidBirthDate': 12,
  'InvalidNationality': 1001,
}

export const ERROR_CODE_LABEL = {
  0: '系統發生錯誤',
  1: '帳號重複',
  2: '帳號或密碼錯誤',
  3: '找不到資料',
  4: '驗證失敗',
  5: '查無會員資料',
  6: '無效的資料',
  7: '錯誤的Email',
  8: '錯誤的Phone',
  9: '會員已被停用',
  10: '發生錯誤，請重新嘗試登入', // 401 - 未正確登入
  11: '發生錯誤，請重新嘗試登入', // 403 - 錯誤的存取權限
  12: '無效的生日',
  1001: '無效的國籍代碼',
  5001: '訂單無法退款',
  5301: '操作已達上限',
  9999: '無法完成動作，請稍後再試',
}

/* NOTE:
 * 回首頁或登入按鈕頁: 8000、80002、8003、8004、8005
 * 導去 OAuth 登入頁: 8001、8006、8007
 */
export const OAUTH_ERROR_CODE_LABEL = {
  8000: '發生錯誤，請重新嘗試登入', // clientId 或 redirectUrl 不符合
  8001: '', // 無效的 access token
  8002: '發生錯誤，請重新嘗試登入', // 不支援的授權類型
  8003: '發生錯誤，請重新嘗試登入', // OAuth 登入頁面已過期，請回原登入頁面重新操作。 (Unauthorized Client)
  8004: '發生錯誤，請重新嘗試登入', // 授權錯誤
  8005: '發生錯誤，請重新嘗試登入', // 此會員帳號不存在 !
  8006: '', // 無效的 refresh token
  8007: '', // refresh token 已使用
}

// logout
export const REDIRECT_ERROR_CODE = [
  10,
  11,
  8001,
  8006,
  8007,
]

// login
export const OAUTH_LOGIN_REDIRECT_ERROR_CODE = [
  8001,
  8006,
  8007,
]

// 重新登入列舉
export const REFRESH_TOKEN_ERROR_CODE = [10, 11]

/* 加入購物車/購買error code
 * 4001: 為多個可能性集合，其中一種可能性，後端檢查數量後發現不夠無法販售
 * 其餘錯誤視情況再進行列舉
 */
export const CART_ERROR_CODE_LABEL = { 4001: '商品已售罄' }