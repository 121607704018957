import { createAsyncThunk, createSlice } from '@reduxjs/toolkit'
import { CART_ERROR_CODE_LABEL } from 'config/error'
import cart from 'apis/product/cart'
import { setLoadingTextState } from 'slices/loadingSlice'
import { setErrorState } from 'slices/errorSlice'

const cartInitial = {
  cartAmount: 0,
  cartProducts: [],
}

export const addCartProduct = createAsyncThunk('cart/addCartProduct',
  async (params, thunkAPI) => {
    thunkAPI.dispatch(setLoadingTextState('加入購物車中...'))
    try {
      const result = await cart.addCartProduct(params)
      const { data } = result

      thunkAPI.dispatch({
        type: 'cart/addCartAmountState',
        payload: data, 
      })
      return data
    } catch (error) {
      // thunkAPI.dispatch(setErrorState(error))
      throw thunkAPI.rejectWithValue(error)
    }
  })


export const getCartInfo = createAsyncThunk('cart/getCartInfo',
  async (params, thunkAPI) => {
    try {
      const result = await cart.getCartInfo()
      const { data } = result
      thunkAPI.dispatch({
        type: 'cart/setCartState',
        payload: data, 
      })
    } catch (error) {
      thunkAPI.dispatch(setErrorState(error))
    }
  })

export const getCartCount = createAsyncThunk('cart/getCartCount', 
  async (params, thunkAPI) => {
    try {
      const result = await cart.getCartCount()
      const { data } = result
      thunkAPI.dispatch({
        type: 'cart/setCartAmountState',
        payload: data, 
      })
    } catch (error) {
      thunkAPI.dispatch(setErrorState(error))
    }
  })

export const cartSlice = createSlice({
  name: 'cart',
  initialState: cartInitial,
  reducers: {
    setCartState(state, action) {
      state.cartAmount = action.payload?.length || 0
      state.cartProducts = action.payload || []
    },
    setCartAmountState(state, action) {
      state.cartAmount = action.payload
    },
    addCartAmountState(state) {
      state.cartAmount++
    },
    minusCartAmountState(state) {
      state.cartAmount--
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase('auth/ecSignIn/fulfilled', (state, action) => {
        state.name = action.payload.name
      })
      .addMatcher((action) => (
        action.type === 'auth/resetAuthSetting' ||
          action.type === 'auth/ecSignOut/fulfilled' ||
          action.type === 'auth/ecSignOut/rejected' ||
          action.type === 'auth/ecRefreshToken/rejected'
      ),
      () => cartInitial)
  },
})

const { actions, reducer } = cartSlice

export const {
  setCartAmountState,
  addCartAmountState,
  minusCartAmountState,
} = actions

export const selectCartAmount = (state) => state.cart.cartAmount
export const selectCartProducts = (state) => state.cart.cartProducts

export default reducer